import React, { useContext, useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { AutoFixNormal, Edit, Delete, Add } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import handleError from "../../../services/handleError";
import ApiServices, { _URL_ASSETS } from "../../../services";
import { SET_PROFILE } from "../../../constants";
import AppContext from "../../../contexts/appContext";
import styles from "./index.module.scss";
import {
  onClear,
  onDone,
  onReset,
  onSign,
} from "../../../vendors/SigWebTablet";
import Utils from "../../../services/utils";

const SignaturePadTopaz = () => {
  const [sigCaptured, setSigCaptured] = useState(null);
  const [myImage, setMyImage] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const {
    state: { auth },
    dispatch,
  } = useContext(AppContext);

  useEffect(() => {
    return () => {
      handleReset();
    };
  }, []);

  const getData = () => {
    ApiServices.getProfileData({ token: auth.token })
      .then((res) => {
        if (res.success === true) {
          dispatch({
            type: SET_PROFILE,
            payload: {
              name: res.payload.name,
              email: res.payload.email,
              signatures: res.payload.signatures,
              options: res.payload.options,
            },
          });
        } else {
          handleError(res.code, dispatch);
        }
      })
      .catch((err) => {
        console.error("===> Error response : ", err);
      });
  };
  const handleAddImage = () => {
    let _signature = {};
    _signature.title = myImage.title;
    _signature.type = "topaz";
    _signature.payload = myImage.payload;

    setSaveLoading(true);
    ApiServices.editProfileData({
      token: auth.token,
      data: { signature: _signature },
    })
      .then((res) => {
        setSaveLoading(false);
        console.log("===> List response: ", res);
        if (res.success === true) {
          getData();
          setMyImage("");
          handleReset();
        } else {
          handleError(res.code, dispatch);
        }
      })
      .catch((err) => {
        console.error("===> Error response : ", err);
        setSaveLoading(false);
      });
  };
  const handleDeleteImage = (_id) => {
    setDeleteLoading(true);
    ApiServices.deleteSignature({
      token: auth.token,
      id: _id,
    })
      .then((res) => {
        setDeleteLoading(false);
        if (res.success === true) {
          getData();
        } else {
          handleError(res.code, dispatch);
        }
      })
      .catch((err) => {
        console.error("===> Error response : ", err);
        setDeleteLoading(false);
      });
  };

  const handleSigCapture = (sig) => {
    let image = new Image();
    image.src = "data:image/png;base64," + sig;
    setSigCaptured({ image, sig });
    setMyImage({ ...myImage, ...{ payload: image.src } });
  };

  const handleSign = () => {
    try {
      onSign();
    } catch (error) {
      console.log("Can't handle sign");
    }
  };
  const handleDone = () => {
    try {
      onDone(handleSigCapture);
    } catch (error) {
      console.log("Can't handle set tablet image on our app");
    }
  };
  const handleClear = () => {
    try {
      onClear();
    } catch (error) {
      console.log("Can't clear tablet");
    }
  };
  const handleReset = () => {
    try {
      onReset();
      setSigCaptured(null);
    } catch (error) {
      console.log("Can't reset tablet");
    }
  };

  return (
    <div className={styles.padContainer}>
      <Box className="input-group" sx={{ mb: 2 }}>
        <label htmlFor="title" style={{ textAlign: "start", display: "block" }}>
          Signature name
        </label>
        <input
          name="title"
          type="text"
          placeholder="Signature name"
          value={myImage?.title || ""}
          onChange={(e) =>
            setMyImage({
              ...myImage,
              ...{ title: e.target.value },
            })
          }
        />
      </Box>
      <canvas
        id="cnv"
        className={styles.cnvs}
        name="cnv"
        width="1400"
        height="280"
      />
      <div className={styles.actionsArea}>
        {!sigCaptured ? (
          <>
            <Button color="inherit" variant="outlined" disableElevation onClick={handleSign}>
              <Trans>sign</Trans>
            </Button>
            <Button color="error" variant="outlined" disableElevation onClick={handleClear}>
              <Trans>clear</Trans>
            </Button>
            <Button
              color="success"
              variant="contained"
              disableElevation
              onClick={handleDone}
            >
              <Trans>done</Trans>
            </Button>
          </>
        ) : (
          <>
            <Button onClick={handleReset}>
              <Trans>reset</Trans>
            </Button>
            <Button
              color="primary"
              disableElevation
              disabled={
                !!myImage && !!myImage.payload && !!myImage.title ? false : true
              }
              variant="contained"
              onClick={handleAddImage}
            >
              {saveLoading ? (
                <CircularProgress
                  color="inherit"
                  style={{
                    width: "18px",
                    height: "18px",
                    marginInlineEnd: "6px",
                  }}
                />
              ) : (
                <Add />
              )}
              <Trans>save</Trans>
            </Button>
          </>
        )}
      </div>
      <div className="stamps-list">
        <Grid container spacing={2}>
          {auth?.userData?.signatures
            ?.filter((f) => f.type === "signature_image")
            .map((item, idx) => (
              <Grid item xs={12} sm={4} md={3} lg={4} key={idx}>
                <div className="item-stamp">
                  <img
                    // src={_URL_ASSETS + item.payload}
                    src={Utils.parseImage(item)}
                    alt=""
                    className="img"
                  />
                  <Typography component="h4" className="title">
                    {item.title}
                  </Typography>
                  <Button
                    color="error"
                    disableElevation
                    variant="outlined"
                    onClick={() => handleDeleteImage(item.id)}
                    sx={{ mt: 1 }}
                  >
                    <Delete />
                    {/* <Trans>general.delete</Trans> */}
                  </Button>
                </div>
              </Grid>
            ))}
        </Grid>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={deleteLoading}
        onClick={() => {}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default SignaturePadTopaz;
