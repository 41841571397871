import {
  Box,
  Button,
  CircularProgress,
  Fade,
  FormControlLabel,
  FormGroup,
  Grid,
  Modal,
  Switch,
  TextField,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "../../../contexts/appContext";
import { STATE_DONE } from "../../../constants";
import ApiServices from "../../../services";
import handleError from "../../../services/handleError";

const SignatureOptionsCard = ({ onSubmit }) => {
  const { t } = useTranslation("translation");
  const {
    state: { auth },
    dispatch,
  } = useContext(AppContext);
  const [options, setOptions] = useState(auth.userData?.options);
  const [saveLoading, setSaveLoading] = useState(false);

  const handaleSubmit = () => {
    setSaveLoading(true);

    ApiServices.editProfileOptions({
      token: auth.token,
      data: { options: {...options} },
    })
      .then((res) => {
        setSaveLoading(false);
        console.log("===> List response: ", res);
        if (res.success === true) {
          onSubmit();
        } else {
          handleError(res.code, dispatch);
        }
      })
      .catch((err) => {
        console.error("===> Error response : ", err);
        setSaveLoading(false);
      });
  };
  const handleChange = (event) => {
    setOptions({
      ...options,
      [event.target.name]: event.target.checked,
    });
  };

  return auth.state === STATE_DONE ? (
    !!auth.userData?.options && (
      <Box sx={{ width: "100%" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={5} md={6} lg={6}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={options.displayOnBottom}
                    onChange={handleChange}
                    name="displayOnBottom"
                  />
                }
                label={
                  options.displayOnBottom ? "Position Bottom" : "Position Right"
                }
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={options.showName}
                    onChange={handleChange}
                    name="showName"
                  />
                }
                label="Show name"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={options.showDate}
                    onChange={handleChange}
                    name="showDate"
                  />
                }
                label="Show Date"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={7} md={6} lg={6}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={options.showReason}
                    onChange={handleChange}
                    name="showReason"
                  />
                }
                label="Show Reason"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={options.jobtitle}
                    onChange={handleChange}
                    name="jobtitle"
                  />
                }
                label="Show title"
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Button
          sx={{ mt: 3, minWidth: saveLoading?20:142 }}
          variant="contained"
          onClick={handaleSubmit}
        >
            {saveLoading?<CircularProgress style={{color: '#fff'}} size={22} />:'Save'}
        </Button>
      </Box>
    )
  ) : (
    <Box sx={{ display: "flex", justifyContent: "center", minHeight: "100vh" }}>
      <CircularProgress />
    </Box>
  );
};

export default SignatureOptionsCard;
