import { Trans, useTranslation } from "react-i18next";
import React, { useContext, useEffect, useRef, useState } from "react";
import SignaturePad from "react-signature-canvas";
import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Fade,
  FormControlLabel,
  FormGroup,
  Grid,
  Modal,
  Paper,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { PhotoCamera } from "@mui/icons-material";
import AppContext from "../../contexts/appContext";
import { STATE_DONE } from "../../constants";
import SignaturePreview from "../../components/SignaturePreview";
import { _URL_ASSETS } from "../../services";
import Utils from "../../services/utils";
import TextSignature from "./TextSignature";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `modal-tab-${index}`,
    "aria-controls": `modal-tabpanel-${index}`,
  };
}

const ModalContent = ({ cancel, updateDetails, saveSignature, comment, setComment }) => {
  const [tabvalue, setTabValue] = useState(0);
  const [fakeLoader, setfakeLoader] = useState(true);
  const { t } = useTranslation("translation");
  const sigCan = useRef(null);

  const [activeTab, setActiveTab] = useState(1);
  const [activePen, setActivePen] = useState({
    index: 0,
    color: "#000000",
  });
  const [mySignature, setMySignature] = useState("");
  const [isMultiPage, setIsMultiPage] = useState(false);
  const [selectLoading, setSelectLoading] = useState(false);
  const [checkSaveSignature, setCheckSaveSignature] = useState(false);
  const [sigIsSelected, setSigIsSelected] = useState(false);
  const {
    state: { auth },
  } = useContext(AppContext);
  const [drawData] = useState([
    {
      background: "#000000",
      active: true,
    },
    {
      background: "#2293FB",
      active: false,
    },
    {
      background: "#ec0f0f",
      active: false,
    },
  ]);

  useEffect(() => {
    setTimeout(() => {
      setfakeLoader(false);
    }, 200);
    return () => {
      setfakeLoader(true);
    };
  }, []);

  const handleTabChange = (event, newValue) => {
    handaleClear();
    setTabValue(newValue);
  };
  const handaleSubmitt = () => {
    updateDetails(mySignature, isMultiPage);
    if (checkSaveSignature) {
      saveSignature(mySignature);
      setCheckSaveSignature(false)
    }
    cancel();
  };
  const handlePenChange = (index, color) => {
    setActivePen((s) => ({ ...s, index, color }));
  };

  const handaleCancel = () => {
    cancel();
  };
  const handaleClear = () => {
    sigCan?.current?.clear();
    setMySignature("");
    setSigIsSelected(false);
    setCheckSaveSignature(false);
    setIsMultiPage(false);
  };
  const handleUploadChange = (e) => {
    // console.log('e: ', e.target.files);
    let file = e.target.files[0];

    Utils.getBase64(file)
      .then((result) => {
        file["base64"] = result;
        setMySignature({...mySignature, tab: getTabValue(), src:result});
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const selectSignature = (_payload) => {
    setSelectLoading(true);
    setSigIsSelected(true)

    setTimeout(() => {
      setSelectLoading(false);

      sigCan.current.fromData(
        _payload?.map((path) =>
          path.map((point) => ({
            x: point.x,
            y: point.y,
            color: activePen.color,
          }))
        )
      );
      setMySignature({...mySignature, tab: getTabValue(), src:sigCan.current.getTrimmedCanvas().toDataURL("image/png")});
    }, 300);
  };
  const handleSelectSavedImage = (item) => {
    let _payload = item.payload_image?item.payload_image:item.payload;
    if (_payload.includes(".png")) {
      setSelectLoading(true);
      let file = _URL_ASSETS + _payload;
      Utils.getBase64FromUrl(file)
        .then((result) => {
          // console.log('RESULT: ', result);
          setMySignature({url: file, tab: getTabValue(), src:result});
          setSelectLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setSelectLoading(false);
        });
      return;
    }
    if (_payload.includes("data:image/png;base64") || _payload.includes("base64")) {
      setMySignature({url: 'any', tab: getTabValue(), src:_payload});
      return;
    }

    if (!_payload.includes(".png") || !_payload.includes("data:image/png;base64")) {
      let result = '';
      try {
        result = Utils.decryptData(JSON.parse(_payload));
      } catch (error) {
        result = Utils.decryptData(_payload);
      }
      setMySignature({url: 'any', tab: getTabValue(), src:result});
      return;
    }    
  };
  const getTabValue = ()=>{
    switch (tabvalue) {
      case 0:
        return 'signature'
      case 1:
        return 'image_signature'
      case 2:
        return 'text_signature'
      case 3:
        return 'saved_signature'
  
      default:
        return null
    }
  }
  return (
    <div className="ijtmaati-signature-modal-content">
      <div className="draw-container" style={{ padding: 0 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabvalue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab label={t("draw")} {...a11yProps(0)} />
            <Tab label={t("image")} {...a11yProps(1)} />
            <Tab label={t("text")} {...a11yProps(2)} />
            <Tab label={t("saved_signatures")} {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={tabvalue} index={0}>
          <div className="draw-container-heading">
            <div className="toggle">
              <FormGroup style={{flexDirection: 'row', alignItems: 'center'}}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isMultiPage}
                      onChange={(event) => setIsMultiPage(event.target.checked)}
                    />
                  }
                  label={t("multi_page_signatures")}
                />
                <FormControlLabel 
                  control={
                    // <Checkbox defaultChecked />
                    <Switch
                      disabled={!mySignature.src}
                      checked={checkSaveSignature}
                      onChange={(event) => setCheckSaveSignature(event.target.checked)}
                    />
                  } 
                  label={t('save_signature')} 
                />

              </FormGroup>
            </div>
            <div className="drower-pens">
              {drawData?.map((draw, index) => {
                return (
                  <div
                    className="drower-pen"
                    key={index}
                    style={{
                      margin: "0",
                      background: draw.background,
                      border:
                        index === activePen.index
                          ? "2px solid #FEC20E"
                          : "none",
                    }}
                    onClick={() => {
                      handlePenChange(index, draw.background);
                    }}
                  ></div>
                );
              })}
            </div>
          </div>
          <div className={sigIsSelected?'draw-area selected-sig':'draw-area'}>
            {!fakeLoader && (
              !sigIsSelected?<SignaturePad
                ref={sigCan}
                penColor={`${activePen.color}`}
                canvasProps={{
                  className: "sigCanvas",
                }}
                clearOnResize={true}
                onEnd={() => {
                  console.log("==> Signature Data: ", sigCan.current.toData());

                  setMySignature({...mySignature, 
                    tab: getTabValue(), 
                    src: sigCan.current.getTrimmedCanvas().toDataURL("image/png"),
                    srcArr: sigCan.current
                    .toData()
                    .map((path) =>
                      path.map((point) => ({ x: point.x, y: point.y }))
                    ),
                  });
                }}
              />:
              <div className="preview">
                  <SignaturePad
                  ref={sigCan}
                  penColor={`${activePen.color}`}
                  canvasProps={{ width: 740, height: 300, className: "sigCanvas" }}
                  clearOnResize={true}
                  onEnd={() => {
                    console.log("==> Signature Data: ", sigCan.current.toData());
                    setMySignature({...mySignature, tab: getTabValue(), src: sigCan.current.getTrimmedCanvas().toDataURL("image/png")});
                  }}
                />
              </div>
            )}
          </div>
          {auth?.userData?.signatures && (
            <p className="inner-title" style={{ margin: 0 }}>
              <Trans>user_signatures</Trans>
            </p>
          )}
          {<div className="signatures_preview_list" style={{ marginTop: 32 }}>
            {auth.state === STATE_DONE ? (
              <Grid container spacing={2}>
                {auth?.userData?.signatures
                  .filter((f) => f.type === "signature")
                  .map((item, idx) => item.payload&&(
                    <Grid item xs={12} sm={6} md={5} lg={4} key={idx}>
                      <SignaturePreview
                        title={item.title}
                        payload={JSON.parse(item.payload)}
                        handleSigClick={() =>
                          selectSignature(JSON.parse(item.payload))
                        }
                      />
                    </Grid>
                  ))}
              </Grid>
            ) : (
              <div style={{ padding: 32 }}>
                <CircularProgress
                  color="inherit"
                  style={{
                    width: "18px",
                    height: "18px",
                    marginInlineEnd: "6px",
                  }}
                />
              </div>
            )}
          </div>}
        </TabPanel>
        <TabPanel value={tabvalue} index={1}>
          <div className="draw-container-heading">
            <div
              className="toggle"
              style={{ display: "flex", flexWrap: "wrap", width: "100%" }}
            >
              <FormGroup style={{flexDirection: 'row', alignItems: 'center'}}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isMultiPage}
                      onChange={(event) => setIsMultiPage(event.target.checked)}
                    />
                  }
                  label={t("multi_page_signatures")}
                />
                <FormControlLabel 
                  control={
                    // <Checkbox defaultChecked />
                    <Switch
                      disabled={!mySignature.src}
                      checked={checkSaveSignature}
                      onChange={(event) => setCheckSaveSignature(event.target.checked)}
                    />
                  } 
                  label={t('save_signature')} 
                />

              </FormGroup>
              <Button
                variant="contained"
                color="primary"
                aria-label="upload picture"
                component="label"
                startIcon={<PhotoCamera />}
                style={{
                  boxShadow: "none",
                  alignSelf: "center",
                  marginInlineStart: "auto",
                }}
              >
                <Trans>upload_signature</Trans>
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  onChange={handleUploadChange}
                />
              </Button>
            </div>
          </div>
          <div className="draw-area">
            <div className="sigCanvas" style={{cursor: 'auto'}}>
              {mySignature && (
                <img
                  src={mySignature.src}
                  style={{
                    height: 250,
                    maxWidth: "100%",
                    objectFit: "contain",
                    padding: 32,
                    marginBottom: 16,
                    objectFit: "contain",
                  }}
                />
              )}
            </div>
          </div>
          {false && auth?.userData?.signatures && (
            <p className="inner-title" style={{ margin: 0 }}>
              <Trans>user_signatures</Trans>
            </p>
          )}
          {false && <div className="signatures_preview_list" style={{ marginTop: 32 }}>
            {auth.state === STATE_DONE ? (
              <Grid container spacing={2}>
                {auth?.userData?.signatures
                  .filter((f) => f.type === "signature_image")
                  .map((item, idx) => (
                    <Grid item xs={12} sm={6} md={5} lg={4} key={idx}>
                      <Paper
                        sx={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                        elevation={0}
                        onClick={() => handleSelectSavedImage(item.payload)}
                      >
                        <img
                          src={_URL_ASSETS + item.payload}
                          style={{
                            maxWidth: "100%",
                            maxHeight: 100,
                            objectFit: "contain",
                            padding: 16,
                            marginBottom: 8,
                            objectFit: "contain",
                            margin: "auto",
                          }}
                        />
                        <h4
                          className="title"
                          style={{
                            margin: "auto 0 0",
                            padding: "4px 8px",
                            fontSize: 14,
                          }}
                        >
                          {item.title}
                        </h4>
                      </Paper>
                    </Grid>
                  ))}
              </Grid>
            ) : (
              <div style={{ padding: 32 }}>
                <CircularProgress
                  color="inherit"
                  style={{
                    width: "18px",
                    height: "18px",
                    marginInlineEnd: "6px",
                  }}
                />
              </div>
            )}
          </div>}
        </TabPanel>
        <TabPanel value={tabvalue} index={2}>
          <div className="draw-container-heading">
            <div
              className="toggle"
              style={{ display: "flex", flexWrap: "wrap", width: "100%" }}
            >
              <FormGroup style={{flexDirection: 'row', alignItems: 'center'}}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isMultiPage}
                      onChange={(event) => setIsMultiPage(event.target.checked)}
                    />
                  }
                  label={t("multi_page_signatures")}
                />
                <FormControlLabel 
                  control={
                    // <Checkbox defaultChecked />
                    <Switch
                      disabled={!mySignature.src}
                      checked={checkSaveSignature}
                      onChange={(event) => setCheckSaveSignature(event.target.checked)}
                    />
                  } 
                  label={t('save_signature')} 
                />
              </FormGroup>
            </div>
          </div>
          <div className="draw-area">
            {mySignature && (
                <img
                  src={mySignature.src}
                  style={{
                    height: 150,
                    maxWidth: "100%",
                    objectFit: "contain",
                    padding: 8,
                    objectFit: "contain",
                    backgroundColor: 'var(--light-color)',
                    borderRadius: 15
                  }}
                />
            )}
            <TextSignature setSignature={setMySignature}/>
          </div>
        </TabPanel>
        <TabPanel value={tabvalue} index={3}>
        <div className="draw-container-heading">
            <div
              className="toggle"
              style={{ display: "flex", flexWrap: "wrap", width: "100%" }}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isMultiPage}
                      onChange={(event) => setIsMultiPage(event.target.checked)}
                    />
                  }
                  label={t("multi_page_signatures")}
                />
              </FormGroup>
            </div>
          </div>
          <div className="draw-area">
            <div className="sigCanvas" style={{cursor: 'auto'}}>
              {mySignature && (
                <img
                  src={mySignature.src}
                  style={{
                    height: 250,
                    maxWidth: "100%",
                    objectFit: "contain",
                    padding: 32,
                    marginBottom: 16,
                    objectFit: "contain",
                  }}
                />
              )}
            </div>
          </div>
          {auth?.userData?.signatures && (
            <p className="inner-title" style={{ margin: 0 }}>
              <Trans>user_signatures</Trans>
            </p>
          )}
          <div className="signatures_preview_list" 
            style={{ 
              marginTop: 32, 
              height: '300px', 
              overflowY: 'auto', 
              padding: '16px', width: 'calc(100% + 64px)', 
              marginInlineStart: '-32px' 
            }}
          >
            {auth.state === STATE_DONE ? (
              <Grid container spacing={2}>
                {auth?.userData?.signatures.filter(item=>item.type==='signature'&&!item.payload_image?false:true).map((item, idx) => (
                    // .filter((f) => f.type === "signature_image")
                    <Grid item xs={6} sm={4} md={3} lg={3} key={idx}>
                      <Paper
                        sx={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          cursor: 'pointer'
                        }}
                        elevation={0}
                        onClick={() => handleSelectSavedImage(item)}
                      >
                        <img
                          src={Utils.parseImage(item)}
                          style={{
                            maxWidth: "100%",
                            maxHeight: 100,
                            objectFit: "contain",
                            padding: 16,
                            marginBottom: 8,
                            objectFit: "contain",
                            margin: "auto",
                          }}
                        />
                        <h4
                          className="title"
                          style={{
                            margin: "auto 0 0",
                            padding: "4px 8px",
                            fontSize: 13,
                            lineHeight: '18px',
                            fontWeight: '600'
                          }}
                        >
                          {item.title}
                        </h4>
                      </Paper>
                    </Grid>
                  ))}
              </Grid>
            ) : (
              <div style={{ padding: 32 }}>
                <CircularProgress
                  color="inherit"
                  style={{
                    width: "18px",
                    height: "18px",
                    marginInlineEnd: "6px",
                  }}
                />
              </div>
            )}
          </div>
        </TabPanel>                
        <>
          <label
            style={{
              fontWeight: 600,
              fontSize: "14px",
              display: "block",
              marginBottom: "8px",
            }}
          >
            {t("meetings.decisions_card.My_Decision.Reason")}
          </label>
          <TextField
            multiline
            maxRows={6}
            onChange={(e) => {
              setComment(e.target.value);
            }}
            value={comment}
            style={{ width: "100%", marginBottom: "32px" }}
          />
        </>
      </div>
      <div className="footer-btn">
        <Button className={"px-4 ml-2"} onClick={handaleCancel}>
          <Trans>tour.close</Trans>
        </Button>
        <Button
          color="error"
          disabled={mySignature !== "" ? false : true}
          variant="contained"
          className="cancel"
          onClick={handaleClear}
        >
          {activeTab === 1 ? (
            <Trans>signature_clear</Trans>
          ) : (
            <Trans>general.cancel</Trans>
          )}
        </Button>
        <Button
          variant="contained"
          className={"px-4 ml-2"}
          onClick={handaleSubmitt}
          disabled={mySignature !== "" ? false : true}
        >
          <Trans>submit</Trans>
        </Button>
      </div>
      <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={selectLoading}
          onClick={()=>{}}
        >
          <CircularProgress color="inherit" />
      </Backdrop>      
    </div>
  );
};

export default function PDFModalSignature({ visible, cancel, updateDetails, saveSignature, comment, setComment }) {
  // reject, sign
  const { t } = useTranslation("translation");
  const modalStyle = {
    position: "absolute",
    // top: "50%",
    left: "50%",
    transform: "translateX(-50%)",
    // width: 400,
    overflowY: "auto",
    top: "16px",
    bgcolor: "#f2f2f2",
    border: "2px solid #fff",
    boxShadow: 24,
    p: 4,
  };
  return (
    <Modal
      title={t("add_signature")}
      open={visible}
      className="ijtmaati-signature-modal"
      onClose={() => cancel()}
      closeAfterTransition
    >
      <Fade in={visible}>
        <Box sx={modalStyle} className="modal-box">
          <h2 className="modal-title" style={{ margin: "0 0 16px" }}>
            <Trans>add_signature</Trans>
          </h2>
          <ModalContent cancel={cancel} updateDetails={updateDetails} saveSignature={saveSignature} comment={comment} setComment={setComment} />
        </Box>
      </Fade>
    </Modal>
  );
}
