import { Box, Button, Fade, Modal, TextField } from "@mui/material";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

const RejectModal = ({ visible, cancel, onReject }) => {
  const [comment, setComment] = useState("");
  const { t } = useTranslation("translation");
  const modalStyle = {
    // position: "absolute",
    // top: "50%",
    // left: "50%",
    // transform: "translate(-50%, -50%)",
    // width: "400px",
    // maxWith: "100%",
    // bgcolor: "#f2f2f2",
    // border: "2px solid #fff",
    // boxShadow: 24,
    // p: 4,
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    overflowY: 'auto',
    top: '16px',
    minWidth: "300px",
    maxWith: "100%",
    bgcolor: "#f2f2f2",
    border: "2px solid #fff",
    boxShadow: 24,
    p: 4,    
  };

  const handaleReject = () => {
    onReject(comment);
    cancel();
  };
  return (
    <Modal
      title={t("reject")}
      open={visible}
      className="ijtmaati-signature-modal"
      onClose={() => cancel()}
      closeAfterTransition
    >
      <Fade in={visible}>
        <Box sx={modalStyle} className="modal-box">
          <div style={{padding: 32, backgroundColor: 'var(--light-color)'}}>
            <h2 style={{ margin: "0 0 32px" }}>
              <Trans>reject</Trans>
            </h2>
            <div className="content">
              <label
                style={{
                  fontWeight: 600,
                  fontSize: "14px",
                  display: "block",
                  marginBottom: "8px",
                }}
              >
                {t("meetings.decisions_card.My_Decision.Reason")}
              </label>
              <TextField
                multiline
                maxRows={6}
                onChange={(e) => {
                  setComment(e.target.value);
                }}
                value={comment}
                style={{ width: "100%" }}
              />
            </div>
            <div style={{ marginTop: "16px", display: "flex", gap: "10px" }}>
              <Button
                variant="outlined"
                className={"px-4 ml-2"}
                onClick={() => {
                  cancel();
                }}
              >
                <Trans>close</Trans>
              </Button>
              <Button
                color="error"
                disabled={comment !== "" ? false : true}
                variant="contained"
                className="cancel"
                onClick={handaleReject}
              >
                <Trans>reject</Trans>
              </Button>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default RejectModal;
