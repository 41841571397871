import React, { useEffect, useRef } from "react";
import SignaturePad from "react-signature-canvas";
const SignaturePreview = ({ title, payload, handleSigClick }) => {
  const sigCan = useRef(null);
  useEffect(() => {
    setTimeout(() => {
      sigCan.current.fromData(
        payload.map((path) =>
          path.map((point) => ({ x: point.x, y: point.y, color: "#000000" }))
        )
      );
    }, 300);
    return () => {};
  }, []);
  return (
    <div className="sigCanvasPreview" onClick={handleSigClick}>
      <div className="pad-wrapper">
        <SignaturePad
          ref={sigCan}
          penColor="green"
          canvasProps={{ width: 740, height: 300, className: "sigCanvasPad" }}
        />
      </div>
      {title&&<h4 className="title">{title}</h4>}
    </div>
  );
};

export default SignaturePreview;
