import { Backdrop, Button, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Trans } from "react-i18next";
import SignaturePad from "react-signature-canvas";
import SignaturePreview from "../../components/SignaturePreview";
import ApiServices, { _URL_ASSETS } from "../../services";
import AppContext from "../../contexts/appContext";
import handleError from "../../services/handleError";
import { AUTH_STATE_UPDATE, SET_PROFILE, STATE_DONE } from "../../constants";
import {
  Add,
  AutoFixNormal,
  Close,
  Delete,
  Edit,
  RestartAlt,
} from "@mui/icons-material";
import { useParams } from "react-router-dom";
import Utils from "../../services/utils";

const DRW2IMG = (type='convert') => {
  const [listData, setListData] = useState([]);
  const { id } = useParams();
  const {
    state: { auth },
    dispatch,
  } = useContext(AppContext);

  useEffect(() => {
    getData();
    return () => {      
    }
  }, [])
  
  const [fakeLoader, setfakeLoader] = useState(true);
  const [mySignature, setMySignature] = useState("");
  const [cvrtdImg, setCvrtdImg] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);
  const [selectLoading, setSelectLoading] = useState(false);
  const sigCan = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      setfakeLoader(false);
    }, 100);

    return () => {};
  }, []);
  // useEffect(() => {
  //   console.log("MySignature: ", mySignature);
  //   if (mySignature && mySignature?.payload?.length > 0) {
  //     setTimeout(() => {
  //       sigCan.current.fromData(
  //         mySignature.payload.map((path) =>
  //           path.map((point) => ({ x: point.x, y: point.y, color: "#000000" }))
  //         )
  //       );
  //     }, 300);
  //   }
  //   return () => {};
  // }, [mySignature]);

  const selectSignature = ({_payload, _id}) => {
    if (_payload?.length > 0) {
      sigCan.current.fromData(
        _payload.map((path) =>
          path.map((point) => ({ x: point.x, y: point.y, color: "#000000" }))
        )
      );
      setTimeout(() => {
        // setMySignature({...mySignature, srcImg:sigCan.current.getTrimmedCanvas().toDataURL("image/png")});
        setCvrtdImg({id: _id, srcImg:sigCan.current.getTrimmedCanvas().toDataURL("image/png")})
      }, 400);
    }
  };

  const handleConvertSignature = () => {
    setSaveLoading(true);
    ApiServices.convertDrawSignatures({
      token: auth.token,
      data: { profile_signature: cvrtdImg.srcImg },
      id: cvrtdImg.id,
    })
      .then((res) => {
        setSaveLoading(false);
        console.log("===> List response: ", res);
          // dispatch({ type: AUTH_STATE_UPDATE });
          // getData();
          setListData([...listData.filter(item=> item.id !== cvrtdImg.id)])
          sigCan?.current?.clear();
          setMySignature("");
          setCvrtdImg("");
      })
      .catch((err) => {
        console.error("===> Error response : ", err);
        setSaveLoading(false);
      });
  };

  const getData = () => {
    ApiServices.getDrawSignatures({ token: auth.token }).then(res=>{
      console.log(res.data);
      setListData(res.data);
    }).catch((err) => {
      console.error("===> Error response : ", err);
    });
  };

  const handleSelectImage = (item)=>{
    let _payload = item.payload_image?item.payload_image:item.payload;
    if (_payload.includes(".png")) {
      setSelectLoading(true);
      let file = _URL_ASSETS + _payload;
      Utils.getBase64FromUrl(file)
        .then((result) => {
          console.log('RESULT: ', result);
          setCvrtdImg({id: item.id, srcImg:result});
          setSelectLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setSelectLoading(false);
        });
      return;
    }
    if (_payload.includes("data:image/png;base64")) {
      setCvrtdImg({id: item.id, srcImg:_payload});
      return;
    }
    if (!_payload.includes(".png")) {
      return;
      let result = '';
      try {
        result = Utils.decryptData(JSON.parse(_payload));
      } catch (error) {
        result = Utils.decryptData(_payload);
      }
      return result;
    }    
  } 

  return fakeLoader ? (
    <></>
  ) : (
    <div className="signature-settings">
      <div className="ijtmaati-signature-modal">
        <div className="ijtmaati-signature-modal-content">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={5} lg={8}>
              <div className="draw-container">
                <div className="draw-area" style={{maxWidth: '100%'}}>
                  {id === "signature"?<SignaturePad
                    ref={sigCan}
                    //   penColor={`${activePen.color}`}
                    canvasProps={{
                      className: "sigCanvas",
                    }}
                    clearOnResize={true}
                    onEnd={() => {
                      console.log("==> Signature Data: ", sigCan.current.toData());
                    }}
                  />:<>Image To convert</>}
                </div>  
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={5} lg={4}>
              <img style={{maxWidth: '100%'}} src={cvrtdImg.srcImg}/>         
            </Grid>
          </Grid>
          <div className="actions">
            <Button
              color="inherit"
              variant="outlined"
              disableElevation
              disabled={
                cvrtdImg && !!cvrtdImg?.id
                  ? false
                  : true
              }
              className="cancel"
              onClick={() => {
                sigCan?.current?.clear();
                setMySignature("");
              }}
            >
              <Close />
              <Trans>cancle</Trans>
            </Button>
            {cvrtdImg && !!cvrtdImg?.id && (
              <>
                <Button
                  color="primary"
                  disableElevation
                  disabled={
                    cvrtdImg && !!cvrtdImg?.id
                      ? false
                      : true
                  }
                  variant="contained"
                  onClick={handleConvertSignature}
                  startIcon={
                    saveLoading && 
                    <CircularProgress
                      color="inherit"
                      style={{
                        width: "18px",
                        height: "18px",
                        marginInlineEnd: "6px",
                      }}
                    />
                  }
                >
                  <Trans>Convert</Trans>
                </Button>                
              </>
            )}
          </div>
          {id === "signature"?
            <div className="signatures_preview_list">
              {listData.length>0 ? (
                <Grid container spacing={2}>
                  {
                    listData?.filter(item=> item.type =="signature"&&(item.payload_image?false:true)).map((item) => (
                      <Grid item xs={6} sm={4} md={4} lg={3} key={item.id}>
                        <SignaturePreview
                          title={item.id}
                          payload={JSON.parse(item.payload)}
                          handleSigClick={() => {
                            selectSignature({_payload: JSON.parse(item.payload), _id: item.id})
                          }}
                        />
                      </Grid>
                    ))                
                  }
                </Grid>
              ) : (
                <div style={{ padding: 32 }}>
                  <CircularProgress
                    color="inherit"
                    style={{
                      width: "18px",
                      height: "18px",
                      marginInlineEnd: "6px",
                    }}
                  />
                </div>
              )}
            </div>:
            <div className="stamps-list">
              <Grid container spacing={2}>
                {listData
                  ?.filter((f) => {
                    if (f.type === "signature" && f.payload_image) {
                      if (f.payload_image.includes("data:image/png;base64") || !f.payload_image.includes(".png")) {
                        return false;
                      }else{
                        return true;
                      }                      
                    }
                    if (f.type !== "signature" && !f.payload_image) {
                      return true;
                    }  

                    const _payload = f.payload_image?f.payload_image:f.payload;
                    if (!_payload.includes("data:image/png;base64") || !_payload.includes(".png")) {
                      return false;
                    }
                })
                  .map((item, idx) => (
                    <Grid item xs={12} sm={4} md={3} lg={4} key={idx}>
                      <div className="item-stamp" style={{cursor: "pointer"}} onClick={()=>handleSelectImage(item)}>
                        <img src={Utils.parseImage(item)} alt="." className="img" />
                        {/* {
                          item.payload.includes(".png")?
                          <img src={_URL_ASSETS + item.payload} alt="." className="img" />
                          :
                          <img src={Utils.decryptData(JSON.parse(item.payload))} alt="." className="img" />
                        } */}
                      <Typography component="h4" className="title">
                        {item.id}
                      </Typography>
                      </div>
                    </Grid>
                  ))}
              </Grid>            
            </div>
          }
        </div>
      </div>
      <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={selectLoading}
          onClick={()=>{}}
        >
          <CircularProgress color="inherit" />
      </Backdrop>      
    </div>
  );

}

export default DRW2IMG