const ApiServices = {};
// Production URL
export const _MainURL = "https://omantel.ijsign.com";
export const _API_URL = "https://omantel.ijsign.com/v1";
export const _URL_ASSETS = "https://omantel.ijsign.com/";

// Development URL
// export const _MainURL = "https://omantel.dev.ijtimaati.com";
// export const _API_URL = "https://omantel.dev.ijtimaati.com/v1";
// export const _URL_ASSETS = "https://omantel.dev.ijtimaati.com/";

ApiServices.getData = async ({ usrToken }, { unique, token }) => {
  let res = await fetch(`${_API_URL}/documents/${unique}/${token}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${usrToken}`,
    },
  });
  res = await res.json();
  return res;
};

ApiServices.postData = async ({ usrToken }, { unique, token }, data) => {
  let res = await fetch(`${_API_URL}/documents/${unique}/${token}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${usrToken}`,
    },
    body: JSON.stringify(data),
  });
  res = await res.json();
  return res;
};

ApiServices.getApprovalsList = async ({ token }) => {
  let res = await fetch(`${_API_URL}/documents/history`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  res = await res.json();
  return res;
};

ApiServices.getProfileData = async ({ token }) => {
  let res = await fetch(`${_API_URL}/profile`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  res = await res.json();
  return res;
};
ApiServices.editProfileData = async ({ token, data }) => {
  let res = await fetch(`${_API_URL}/profile`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });
  res = await res.json();
  return res;
};
ApiServices.editProfileOptions = async ({ token, data }) => {
  let res = await fetch(`${_API_URL}/profile`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });
  res = await res.json();
  return res;
};
ApiServices.getDrawSignatures = async ({ token }) => {
  let res = await fetch(`${_API_URL}/profile/signatures`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  res = await res.json();
  return res;
};
ApiServices.convertDrawSignatures = async ({ token, data, id }) => {
  // https://omantel.dev.ijtimaati.com/v1/profile/3/update-profile-signature
  let res = await fetch(`${_API_URL}/profile/${id}/update-profile-signature`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });
  res = await res.json();
  return res;
};

ApiServices.editSignature = async ({ token, data, id }) => {
  let res = await fetch(`${_API_URL}/profile/signature/${id}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });
  res = await res.json();
  return res;
};

ApiServices.deleteSignature = async ({ token, id }) => {
  let res = await fetch(`${_API_URL}/profile/signature/${id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }
  });
  res = await res.json();
  return res;
};

ApiServices.createApproval = async ({ token, data }) => {
  let res = await fetch(`${_API_URL}/documents/store`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });
  res = await res.json();
  return res;
};

export default ApiServices;
