import React, { useEffect, useRef, useState } from 'react'

const PageNumberComponent = ({currentPage, totalPages, setPage}) => {
    // const [inputValue, setInputValue] = useState(currentPage);
    const inputRef = useRef(null);
    const [changingPageNumber, setChangingPageNumber] = useState(false);
    useEffect(()=>{
        // setInputValue(currentPage)
        return()=>{
            // setInputValue(currentPage)
        }
    }, [])
    return (
        <div>
            {changingPageNumber ?
                <input
                autoFocus
                type="number"
                style={{
                    margin: 0,
                    width: "30px",
                    backgroundColor: "#ddd",
                    textAlign: "center",
                    lineHeight: "22px",
                    borderRadius: "4px",
                    border: "none",
                }}
                defaultValue={currentPage}
                onFocus={(event)=>{event.target.select()}}
                onKeyDown={(event) => {
                    if (event.key === "Enter") {
                        if (event.target.value <= 0 || event.target.value > totalPages) {
                            setPage(currentPage);
                        } else {
                            setPage(parseInt(event.target.value));
                        }
                        setChangingPageNumber(false);
                    }
                }}
                />                
                :
                <p style={{
                    margin: 0,
                    minWidth: '30px',
                    backgroundColor: 'var(--bg-color)',
                    textAlign: 'center',
                    lineHeight: '22px',
                    borderRadius: '2px',
                    border: 'none',
                    cursor: 'pointer',
                }}
                    onClick={() => {
                        setChangingPageNumber(true)
                    }}
                >
                    {currentPage}
                </p>}
        </div>
    )
}

export default PageNumberComponent