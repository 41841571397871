import React, { useReducer } from "react";
import authReducer from "./auth";
import { STATE_DONE } from "../constants";

const initialState = {
  auth: {
    authed: false,
    token: null,
    session_id: null,
    userData: null,
    state: STATE_DONE,
  },
};

const AppContext = React.createContext();

const mainReducer = (state, action) => {
  return {
    auth: authReducer(state.auth, action),
  };
};
export const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);
  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};
export default AppContext;
