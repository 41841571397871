import { FileHeader } from "./FileHeader";
import {
  Alert,
  CircularProgress,
  LinearProgress,
  Snackbar,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
// import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import PDFModalSignature from "./PDFModalSignature";
import interact from "interactjs";
import RejectModal from "./RejectModal";
import ApiServices from "../../services";
import PDFModalStamp from "./PDFModalStamp";
import { useNavigate, useParams } from "react-router-dom";
import AppContext from "../../contexts/appContext";
import { v4 as uuid } from "uuid";
import {
  AUTH_STATE_DONE,
  AUTH_STATE_UPDATE,
  SET_PROFILE,
  SIGN_OUT,
  STATE_DONE,
  STATE_WAIT,
} from "../../constants";
import handleError from "../../services/handleError";
import AppearanceModal from "./AppearanceModal";
import Utils from "../../services/utils";
import moment from "moment";
import Moment from "react-moment";

// documents/{unique}/{token}
pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.js";

const PDFApproval = () => {
  const { t } = useTranslation("translation");
  const {
    state: { auth },
    dispatch,
  } = useContext(AppContext);

  const returnURL = new URLSearchParams(document.location.search).get(
    "returnUrl"
  );
  const options = {
    cMapUrl: "cmaps/",
    cMapPacked: true,
    standardFontDataUrl: "standard_fonts/",
  };

  const [file, setFile] = useState("");
  const [fileTitle, setFileTitle] = useState("");
  const [canSign, setCanSign] = useState(false);
  const [canDownload, setCanDownload] = useState(false);
  const [canNotSignNote, setCanNotSignNote] = useState(false);
  const [PDFCurrentPage, setPDFCurrentPage] = useState(1);
  const [PDFNumPages, setPDFNumPages] = useState(1);
  const [progress, setProgress] = useState(0);
  const [signatureImages, setSignatureImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageChanged, setselectedImageChanged] = useState(false);
  const [activeSignature, setActiveSignature] = useState(null);
  const [activeStamp, setActiveStamp] = useState(null);
  const [stampImages, setStampImages] = useState([]);
  const [selectedStamp, setSelectedStamp] = useState(null);
  const [selectedStampChanged, setselectedStampChanged] = useState(false);
  const [visibleSignatureModal, setVisibleSignatureModal] = useState(false);
  const [visibleStampModal, setVisibleStampModal] = useState(false);
  const [visibleRejectModal, setVisibleRejectModal] = useState(false);
  const [visibleGlobalAppearanceModal, setVisibleGlobalAppearanceModal] = useState(false);
  const [visibleAppearanceModal, setVisibleAppearanceModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [signComment, setSignComment] = useState("");
  const [docTransform, setDocTransform] = useState("translateY(0) scale(1)");
  const [isMultiPage, setIsMultiPage] = useState(false);
  const [signOptions, setSignOptions] = useState({
    displayOnBottom : false ,
    showName : false ,
    jobtitle : false ,
    showDate : false ,
    showReason: false     
  });
  const docRef = useRef();
  const signatureRef = useRef();
  const headRef = useRef();
  const { unique, token } = useParams();
  const [signatureImageUrl, setSignatureImageUrl] = useState("");
  const [saveSignatureLoading, setSaveSignatureLoading] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    if (!auth.token) {
      return;
    }
    getProfileData();
    getFileData();
    return () => {};
  }, []);
  const UI_OF_DELETE = ()=>{
    if (document.querySelector('.signature_images')) {
      // if (Utils.is_touch_enabled()) {
      //   document.querySelector('.signature_images').addEventListener('touchstart', (e)=>{
      //     document.querySelectorAll('.signature-resize-drag').forEach((el) => {
      //       if (!el.contains(e.target)) {
      //         // remove active signature
      //         setActiveSignature(null);
      //       }else{
      //         // do no thing
      //       }
      //     })
      //     document.querySelectorAll('.stamp-resize-drag').forEach((el) => {
      //       if (!el.contains(e.target)) {
      //         // remove active stamp
      //         setActiveStamp(null);
      //       }else{
      //         // do no thing
      //       }
      //     })
      //   },true)
      // }else{
        document.querySelector('.signature_images').addEventListener('mousedown', (e)=>{
          document.querySelectorAll('.signature-resize-drag').forEach((el) => {
            if (!el.contains(e.target)) {
              // remove active signature
              setActiveSignature(null);
            }else{
              // do no thing
            }
          })
          document.querySelectorAll('.stamp-resize-drag').forEach((el) => {
            if (!el.contains(e.target)) {
              // remove active stamp
              setActiveStamp(null);
            }else{
              // do no thing
            }
          })
        },true)
      // }
    }
  }
  const getFileData = () => {
    ApiServices.getData({ usrToken: auth.token }, urlParams())
      .then((res) => {
        dispatch({ type: AUTH_STATE_DONE });
        if (res.success === true) {
          setFile(res.payload.document);
          setFileTitle(!!res.payload.filename? res.payload.filename:res.payload.title);
          setCanSign(res.payload.can_sign);
          setCanDownload(res.payload.can_download);
          setCanNotSignNote(res.payload.note);
        } else {
          handleError(res.code, dispatch);
        }

        // setFile("/manual-en.pdf");
        // setCanSign(true);
      })
      .catch((err) => {
        console.log("ERROr: ", err);
        dispatch({ type: AUTH_STATE_DONE });
      });
  };
  const urlParams = () => {
    return {
      unique: unique,
      token: token,
    };
  };
  const getProfileData = () => {
    ApiServices.getProfileData({ token: auth.token })
      .then((res) => {
        if (res.success === true) {
          dispatch({
            type: SET_PROFILE,
            payload: {
              name: res.payload.name,
              email: res.payload.email,
              signatures: res.payload.signatures,
              options: res.payload.options,
              title: res.payload.title
            },
          });
          setSignOptions(res.payload.options)
        } else {
          handleError(res.code, dispatch);
        }
      })
      .catch((err) => {
        console.error("===> Error response : ", err);
      });
  };
  const onDocumentLoadSuccess = ({ numPages }) => {
    setPDFNumPages(numPages);
  };
  const cancelModal = () => {
    setVisibleSignatureModal(false);
    setVisibleStampModal(false);
    setVisibleRejectModal(false);
    setVisibleAppearanceModal(false);
    setVisibleGlobalAppearanceModal(false);
  };

  const addStamp = (_stampImage) => {
    setActiveSignature(null);
    setActiveStamp(null);
    
    addStampImage({
      id: uuid(),
      pageNum: PDFCurrentPage,
      src: _stampImage,
      type: "stamp",
      px: ((signatureRef.current.clientWidth/2)-(signatureRef.current.clientWidth/4)),
      py: signatureRef.current.clientHeight/2.8,
      pageWidth: signatureRef.current.clientWidth,
      pageHeight: signatureRef.current.clientHeight,
      width: docRef?.current.clientWidth / 2,
    });
  };
  const saveSignature = ({src, tab, srcArr})=>{
    let _signature = {};
    _signature.type = tab === 'signature'?'signature':'signature_image';

    // TODO Add only on type == signature
    // payload_image    
    if (tab === 'signature') {
      _signature.payload_image = src;
      _signature.payload = JSON.stringify(srcArr);
    } else{
      _signature.payload = src;
    }   
    var now = new Date();

    let _timestamp = now.getFullYear().toString(); // 2011
    _timestamp += (now.getMonth()+1).toString(); // JS months are 0-based, so +1 and pad with 0's
    _timestamp += now.getDate().toString() + ' ';
    _timestamp += now.getHours().toString() + ':';
    _timestamp += now.getMinutes().toString() + ':';
    _timestamp += now.getSeconds().toString();

    _signature.title = `Signature ${_timestamp}`;

    setSaveSignatureLoading(true);
    ApiServices.editProfileData({
      token: auth.token,
      data: { signature: _signature },
    })
      .then((res) => {
        setSaveSignatureLoading(false);
        console.log("===> List response: ", res);
        if (res.success === true) {
          getProfileData();
          setToastMessage('Signature Saved Successfully!');
        } else {
          handleError(res.code, dispatch);
        }
      })
      .catch((err) => {
        console.error("===> Error response : ", err);
        setSaveSignatureLoading(false);
      });    
  }
  const updateDetails = (_signatureImage, isMultiPage) => {
    setActiveSignature(null);
    setActiveStamp(null);

    if (!!_signatureImage.url) {
      setSignatureImageUrl(_signatureImage.url);
    }
    if (!!isMultiPage) {
      setIsMultiPage(true);
      let list = [];
      for (let index = 0; index < PDFNumPages; index++) {
        list.push({
          id: uuid(),
          pageNum: index + 1,
          type: "signature",
          tab: _signatureImage.tab,
          src: _signatureImage.src,
          px: ((signatureRef.current.clientWidth/2)-(signatureRef.current.clientWidth/4)),
          py: signatureRef.current.clientHeight/2.8,
          pageWidth: signatureRef.current.clientWidth,
          pageHeight: signatureRef.current.clientHeight,
          width: docRef?.current.clientWidth / 4,
          options:signOptions,          
        });
      }
      setSignatureImages([...list]);
    } else {
      addSignatureImage({
        id: uuid(),
        pageNum: PDFCurrentPage,
        type: "signature",
        tab: _signatureImage.tab,
        src: _signatureImage.src,
        px: ((signatureRef.current.clientWidth/2)-(signatureRef.current.clientWidth/4)),
        py: signatureRef.current.clientHeight/2.8,
        pageWidth: signatureRef.current.clientWidth,
        pageHeight: signatureRef.current.clientHeight,
        width: docRef?.current.clientWidth / 4,
        options: signOptions,       
      });
    }
  };

  const addSignatureImage = (_img) => {
    let list = signatureImages;
    list.push(_img);
    setSignatureImages([...list]);
  };

  const editSignatureImage = (_img) => {
    let list = signatureImages;
    const found = list.find((el) => el.id === _img.id);
    if (!found) {
      list.push(_img);
    } else {
      list = list.map((el) => {
        if (el.id === found.id) {
          return _img;
        } else {
          return el;
        }
      });
    }
    setSignatureImages([...list]);
  };

  const addStampImage = (_img) => {
    let list = stampImages;
    list.push(_img);
    setStampImages([...list]);
  };

  const editStampImage = (_img) => {
    let list = stampImages;
    const found = list.find((el) => el.id === _img.id);
    if (!found) {
      list.push(_img);
    } else {
      list = list.map((el) => {
        if (el.id === found.id) {
          return _img;
        } else {
          return el;
        }
      });
    }
    setStampImages([...list]);
  };

  const clearData = () => {
    setSignatureImages([]);
    setSelectedImage(null);
    setStampImages([]);
    setSelectedStamp(null);
  };
  const getSelectedImage = () => {
    setSelectedImage(
      signatureImages.filter((img) => img.pageNum === PDFCurrentPage)
    );
  };
  const getSelectedStamp = () => {
    setSelectedStamp(
      stampImages.filter((img) => img.pageNum === PDFCurrentPage)
    );
  };

  useEffect(() => {
    setActiveSignature(null);
    setActiveStamp(null);
    setTimeout(() => {
      signatureRef.current.style.width = docRef?.current.clientWidth + "px";
      signatureRef.current.style.height = docRef?.current.clientHeight + "px";
    }, 300);
    return () => {};
  }, [PDFCurrentPage]);

  useEffect(() => {
    if (signatureImages.length > 0) {
      getSelectedImage();
    }else{
      setSelectedImage([])
    }
    console.log('==> IMGS: ', signatureImages);
    return () => {};
  }, [signatureImages, PDFCurrentPage]);

  useEffect(() => {
    if (stampImages.length > 0) {
      getSelectedStamp();
    }else{
      setSelectedStamp([])
    }
    return () => {};
  }, [stampImages, PDFCurrentPage]);

  useEffect(() => {
    if (selectedImage) {
      // setselectedImageChanged(false);
      // setTimeout(() => {
      //   setselectedImageChanged(true);
      //   setActiveSignature(null)
      // }, 50);
      setTimeout(() => {
        handleInteract(selectedImage, isMultiPage);
        UI_OF_DELETE()
        setActiveStamp(null);
        setActiveSignature(null)
        signatureRef.current.style.width = docRef?.current.clientWidth + "px";
        signatureRef.current.style.height = docRef?.current.clientHeight + "px";        
      }, 200);
    }
    return () => {};
  }, [selectedImage]);

  useEffect(() => {
    if (selectedStamp) {
      // setselectedStampChanged(false);
      // setTimeout(() => {
      //   setselectedStampChanged(true);
      // }, 50);
      // setTimeout(() => {
        handleStampInteract(selectedStamp);
        UI_OF_DELETE()
      // }, 200);
    }
    return () => {};
  }, [selectedStamp]);

  const handleInteract = SignatureInteract(
    signatureRef,
    editSignatureImage,
    setSignatureImages,
    PDFNumPages,
    "signature"
  );
  const handleStampInteract = SignatureInteract(
    signatureRef,
    editStampImage,
    setStampImages,
    PDFNumPages,
    "stamp"
  );
  const submitSign = () => {
    setIsSubmitting(true);
    const paramsArr = [...signatureImages.map(img=>({
      pageNum:img.pageNum,
      type:img.type,
      tab: img.tab||"",
      src:img.src,
      px:img.px,
      py:img.py,
      pageWidth:img.pageWidth,
      pageHeight:img.pageHeight,
      width:img.width,
      height:img.height,
      options: img.options,
    })), ...stampImages.map(img=>({
      pageNum:img.pageNum,
      type:img.type,
      src:img.src,
      px:img.px,
      py:img.py,
      pageWidth:img.pageWidth,
      pageHeight:img.pageHeight,
      width:img.width,
      height:img.height,
      //options: img.options,
      options: {
        displayOnBottom : false ,
        showName : false ,
        jobtitle : false ,
        showDate : false ,
        showReason: false     
      }
    }))];

    const params = {
      action: "sign", //"reject"
      reason: signComment,
      has_returnurl: !!returnURL ? true : false,
      sign_data: [...paramsArr],
    };

    ApiServices.postData({ usrToken: auth.token }, urlParams(), params)
      .then((res) => {
        if (res.success === true) {
          setToastMessage("Successfully saved data");
          if (!!returnURL) {
            console.log('==> Decoded Url: ', window.atob(returnURL));
            window.location.href = window.atob(returnURL);
          } else {
            dispatch({ type: AUTH_STATE_UPDATE });
            clearData();
            getFileData();
          }
        } else {
          handleError(res.code, dispatch);
        }
        setIsSubmitting(false);
      })
      .catch((err) => {
        setIsSubmitting(false);
      });
  };

  const onReject = (comment) => {
    const params = {
      action: "reject", //"reject"
      reason: comment,
      has_returnurl: !!returnURL ? true : false,
      sign_data: [],
    };
    ApiServices.postData({ usrToken: auth.token }, urlParams(), params)
      .then((res) => {
        if (res.success === true) {
          setToastMessage("Rejected Successfully");
          if (!!returnURL) {
            window.location.href = returnURL;
          }
        } else {
          handleError(res.code, dispatch);
        }
        setIsSubmitting(false);
      })
      .catch((err) => {
        // console.log("=> Post errponse", err);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      console.log('==> CHECK Doc: ', document.body.clientWidth);
      console.log('==> CHECK Doc Ref: ', docRef?.current?.clientWidth);
      if (((document.body.clientWidth - 32) / docRef?.current?.clientWidth) < 1) {
        setDocTransform(
          `translateY(${((document.body.clientWidth - 32) / docRef?.current?.clientWidth - 1)/2 * 100}%)`+` scale(${(document.body.clientWidth - 32) / docRef?.current?.clientWidth})`
        );
        docRef?.current.classList.add('isScalled');
      }
    }, 400);
    return () => {};
  }, [docRef?.current?.clientWidth]);

  const handleDeleteActiveSignature = ()=>{
    let list = signatureImages;
    setSignatureImages([...list.filter((img)=>img.id!==activeSignature.id)]);    
    setActiveSignature(null);
  }

  const submitGlobalAppearance = (opt)=>{
    setSignOptions(opt);
  }
  const submitSignatureAppearance = (opt)=>{
    let list = signatureImages;
    // if (aplyAll) {
    //   list = [...list.map((img)=>({...img, options: opt}))]
    // }else{
    list.find((img)=>img.id===activeSignature.id).options = opt
    // }
    setSignatureImages([...list]);
  }

  const handleDeleteActiveStamp = ()=>{
    let list = stampImages;
    setStampImages([...list.filter((img)=>img.id!==activeStamp.id)]);    
    setActiveStamp(null);    
  }

  return auth.state === STATE_DONE ? (
    <div className="pdf-approval">
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!!toastMessage}
        autoHideDuration={6000}
        onClose={() => {
          setToastMessage("");
        }}
      >
        <Alert
          onClose={() => {
            setToastMessage("");
          }}
          severity="success"
          sx={{ width: "100%" }}
        >
          {toastMessage}
        </Alert>
      </Snackbar>
      <FileHeader
        headRef={headRef}
        setPDFCurrentPage={setPDFCurrentPage}
        signatureImages={signatureImages}
        activeSignature={activeSignature}
        deleteSignature= {handleDeleteActiveSignature}
        activeStamp= {activeStamp}
        deleteStamp = {handleDeleteActiveStamp}
        stampImages={stampImages}
        PDFCurrentPage={PDFCurrentPage}
        PDFNumPages={PDFNumPages}
        canSign={canSign}
        canDownload={canDownload}
        file={file}
        fileTitle={fileTitle}
        setVisibleRejectModal={setVisibleRejectModal}
        t={t}
        setVisibleSignatureModal={(val) => {
          setIsMultiPage(false);
          setVisibleSignatureModal(val);
        }}
        setVisibleStampModal={setVisibleStampModal}
        setVisibleGlobalAppearanceModal={setVisibleGlobalAppearanceModal}
        setVisibleAppearanceModal={setVisibleAppearanceModal}
        isSubmitting={isSubmitting}
        submitSign={submitSign}
        canNotSignNote={canNotSignNote}
      />
      {progress < 100 && (
        <LinearProgress variant="determinate" value={progress} />
      )}
      {file && (
        <div className="pdf-doc-wrapper">
          <div
            ref={docRef}
            className="docshadow"
            style={{ transform: docTransform }}
          >
            <Document
              file={file}
              onLoadSuccess={onDocumentLoadSuccess}
              options={options}
              className="pdfDoc"
              onLoadProgress={(e) => {
                setProgress((100 * e.loaded) / e.total);
                if (e < 100) {
                  return;
                } else {
                  setTimeout(() => {
                    signatureRef.current.style.width =
                      docRef?.current.clientWidth + "px";
                    signatureRef.current.style.height =
                      docRef?.current.clientHeight + "px";
                  }, 300);
                }
              }}
            >
              <Page className="pdfPage" pageNumber={PDFCurrentPage} />
            </Document>
            <div ref={signatureRef} className="signature_images">
              {
              // selectedStampChanged && 
              selectedStamp && (
                selectedStamp.map((img) => (
                  <div
                    key={img.id}
                    className="stamp-resize-drag"
                    style={{
                      width: !!img.width
                        ? img.width
                        : docRef?.current.clientWidth / 4,

                      height: !!img.height ? img.height : "auto",
                      transform: `translate(${img.px}px, ${img.py}px)`,
                    }}
                    data-x={img.px}
                    data-y={img.py}
                    data-id={img.id}
                    onClick={()=>{ 
                      setActiveStamp(img)
                    }}
                  >
                    <img src={img.src} />
                    <div className="rs-hdlr rs-hdl-t"></div>
                    <div className="rs-hdlr rs-hdl-r"></div>
                    <div className="rs-hdlr rs-hdl-b"></div>
                    <div className="rs-hdlr rs-hdl-l"></div>                     
                  </div>
                ))
              )}
              {
              // selectedImageChanged && 
              selectedImage && (
                  selectedImage.map((img) => (
                    <div
                      key={img.id}
                      className={`signature-resize-drag ${img.options.displayOnBottom?'options-bottom':''}`}
                      style={{
                        width: !!img.width ? img.width : docRef?.current.clientWidth / 4,
                        height: !!img.height ? img.height : "auto",
                        transform: `translate(${img.px}px, ${img.py}px)`,
                      }}
                      data-x={img.px}
                      data-y={img.py}
                      data-id={img.id}
                      onClick={()=>{ 
                        setActiveSignature(img)
                      }}
                    >
                      <img src={img.src} />
                      <div className="rs-hdlr rs-hdl-t"></div>
                      <div className="rs-hdlr rs-hdl-r"></div>
                      <div className="rs-hdlr rs-hdl-b"></div>
                      <div className="rs-hdlr rs-hdl-l"></div>
                      { (img.options.showName||img.options.jobtitle||img.options.showDate||img.options.showReason) && 
                        (<div className="info-wrapper" style={{
                          width: img.width<180? 
                                180: 
                                (img.width>(docRef?.current.clientWidth/3)?docRef?.current.clientWidth/3:img.width)
                        }}>
                          {img.options.showName && auth?.userData?.name && <span className="name">{auth?.userData?.name}</span>}
                          {img.options.jobtitle && auth?.userData?.title && <span className="title">{auth?.userData?.title}</span>}
                          {img.options.showDate && <span className="date"><Moment date={moment()} format="YYYY/MM/DD hh:m"/></span>}
                          {img.options.showReason && signComment && <p className="reason">{signComment}</p>}
                        </div>)
                      }
                    </div>                    
                  ))               
              )}
            </div>
          </div>
        </div>
      )}
      {visibleSignatureModal && (
        <PDFModalSignature
          visible={visibleSignatureModal}
          cancel={cancelModal}
          updateDetails={updateDetails}
          saveSignature = {saveSignature}
          comment = {signComment}
          setComment = {setSignComment}
        />
      )}
      {visibleStampModal && (
        <PDFModalStamp
          visible={visibleStampModal}
          cancel={cancelModal}
          addStamp={addStamp}
        />
      )}

      {visibleGlobalAppearanceModal && (
        <AppearanceModal
          visible={visibleGlobalAppearanceModal}
          cancel={cancelModal}
          onSubmit={submitGlobalAppearance}
          appearanceOptions={signOptions}
        />
      )}
      {visibleAppearanceModal && (
        <AppearanceModal
          visible={visibleAppearanceModal}
          cancel={cancelModal}
          onSubmit={submitSignatureAppearance}
          activeSignature={activeSignature}
        />
      )}
      {visibleRejectModal && (
        <RejectModal
          visible={visibleRejectModal}
          cancel={cancelModal}
          onReject={onReject}
        />
      )}
    </div>
  ) : (
    <>
      <CircularProgress
        color="inherit"
        style={{
          width: "18px",
          height: "18px",
          marginInlineEnd: "6px",
        }}
      />
    </>
  );
};

export default PDFApproval;

function SignatureInteract(
  signatureRef,
  editSignatureImage,
  setSignatureImages,
  _PDFNumPages,
  _selector
) {
  return (_img, isMultiPage) => {
    const position = { x: _img.px, y: _img.py };
    const widgets = document.querySelectorAll("." + _selector + "-resize-drag");
    interact("." + _selector + "-resize-drag")
      .resizable({
        // resize from all edges and corners
        // margin: ((document.body.clientWidth - 32) / signatureRef?.current?.clientWidth) < 1?36:16,
        margin: 36,
        // edges: { 
        //   left  : '.rs-hdl-l', 
        //   right : '.rs-hdl-r', 
        //   bottom: '.rs-hdl-b', 
        //   top   : '.rs-hdl-t' 
        // },
        edges: { 
          left  : ['.rs-hdl-l', '.rs-hdl-t', '.rs-hdl-l',], 
          right : ['.rs-hdl-r','.rs-hdl-b', '.rs-hdl-r'], 
          // bottom: '.rs-hdl-b', 
          // top   : '.rs-hdl-t' 
        },        
        // edges: { 
        //   left  : true, 
        //   right : true, 
        //   bottom: true, 
        //   top   : true 
        // },
        
        listeners: {
          start(event) {
            event.target.setAttribute("selected", true);
          },          
          move(event) {
            var target = event.target;
            position.x = parseFloat(target.getAttribute("data-x")) || 0;
            position.y = parseFloat(target.getAttribute("data-y")) || 0;

            // update the element's style
            if (((document.body.clientWidth - 32) / signatureRef?.current?.clientWidth) < 1) {
              target.style.width = parseInt(event.rect.width / ((document.body.clientWidth - 32) / signatureRef?.current?.clientWidth)) + "px";
              target.style.height = parseInt(event.rect.height / ((document.body.clientWidth - 32) / signatureRef?.current?.clientWidth)) + "px";              
            } else {
              target.style.width = parseInt(event.rect.width) + "px";
              target.style.height = parseInt(event.rect.height) + "px";
            }
            
            // translate when resizing from top or left edges
            position.x += event.deltaRect.left;
            position.y += event.deltaRect.top;
            target.style.transform = "translate(" + position.x + "px," + position.y + "px)";
            target.setAttribute("data-x", position.x);
            target.setAttribute("data-y", position.y);
          },
          end(event) {
            widgets.forEach((el) => {
              const elID = el.dataset.id;
              if (!el.getAttribute("selected")) {
                return;
              }
              const { x: dataX = "0", y: dataY = "0" } = el.dataset;
              const x = (parseFloat(dataX) || 0) + event.delta.x;
              const y = (parseFloat(dataY) || 0) + event.delta.y;
              let _image = {};
              console.log('==> CHECK Doc: ', document.body.clientWidth);
              console.log('==> CHECK Doc Ref: ', signatureRef?.current?.clientWidth);              
              console.log('==> CHECK : ', ((document.body.clientWidth - 32) / signatureRef?.current?.clientWidth));              
              if (((document.body.clientWidth - 32) / signatureRef?.current?.clientWidth) < 1) {
                _image = {
                  ..._img.find(img=>elID === img.id),
                  px: x,
                  py: y,
                  width: parseInt(event.rect.width / ((document.body.clientWidth - 32) / signatureRef?.current?.clientWidth)),
                  height: parseInt(event.rect.height / ((document.body.clientWidth - 32) / signatureRef?.current?.clientWidth)),
                  pageWidth: signatureRef?.current?.clientWidth,
                  pageHeight: signatureRef?.current?.clientHeight,
                }
              }else{
                _image = {
                  ..._img.find(img=>elID === img.id),
                  px: x,
                  py: y,
                  width: parseInt(event.rect.width),
                  height: parseInt(event.rect.height),
                  pageWidth: signatureRef?.current?.clientWidth,
                  pageHeight: signatureRef?.current?.clientHeight,
                }
              }
  
              if (isMultiPage) {
                const list = [];
                for (let index = 0; index < _PDFNumPages; index++) {
                  list.push({ ..._image, ...{ pageNum: index + 1 } });
                }
                setSignatureImages([...list]);
              } else {
                editSignatureImage(_image);
              }
            }) 
            event.target.removeAttribute("selected");
          },
        },
        
        modifiers: [
          // keep the edges inside the parent
          interact.modifiers.restrictEdges({
            outer: "parent",
          }),
          // interact.modifiers.restrictRect({
          //   restriction: 'parent',
          //   endOnly: true
          // }),
          // minimum size
          interact.modifiers.restrictSize({
            min: { width: 20, height: 10 },
          }),
          interact.modifiers.aspectRatio({
            // make sure the width is always double the height
            // ratio: 2,
            // also restrict the size by nesting another modifier
            modifiers: [interact.modifiers.restrictSize({ max: "parent" })],
          }),
        ],

        inertia: true,
      })
      .draggable({
        modifiers: [
          interact.modifiers.restrictRect({
            restriction: 'parent',
            endOnly: true
          })
        ],
        inertia: true,
        listeners: {
          start(event) {
            event.target.setAttribute("selected", true);
          },
          move(event) {
            widgets.forEach((el) => {
              if (!el.getAttribute("selected")) {
                return;
              }
              const { x: dataX = "0", y: dataY = "0" } = el.dataset;

              const x = (parseFloat(dataX) || 0) + event.delta.x;
              const y = (parseFloat(dataY) || 0) + event.delta.y;

              el.style.transform = `translate(${x}px, ${y}px)`;
              Object.assign(el.dataset, { x, y });
            });
          },
          end(event) {
            widgets.forEach((el) => {
              const elID = el.dataset.id;
              if (!el.getAttribute("selected")) {
                return;
              }
              const { x: dataX = "0", y: dataY = "0" } = el.dataset;
              const x = (parseFloat(dataX) || 0) + event.delta.x;
              const y = (parseFloat(dataY) || 0) + event.delta.y;

              const _image =
              (document.body.clientWidth - 32) / signatureRef?.current?.clientWidth < 1 ? 
                {
                    ..._img.find(img=>elID === img.id),
                    px: x,
                    py: y,
                    width: parseInt( event.rect.width / ((document.body.clientWidth - 32) / signatureRef?.current?.clientWidth) ),
                    height: parseInt( event.rect.height / ((document.body.clientWidth - 32) / signatureRef?.current?.clientWidth) ),
                    pageWidth: signatureRef?.current?.clientWidth,
                    pageHeight: signatureRef?.current?.clientHeight,
                }
                : 
                {
                    ..._img.find(img=>elID === img.id),
                    px: x,
                    py: y,
                    width: parseInt(event.rect.width),
                    height: parseInt(event.rect.height),
                    pageWidth: signatureRef?.current?.clientWidth,
                    pageHeight: signatureRef?.current?.clientHeight,
                };

              if (isMultiPage) {
                const list = [];
                for (let index = 0; index < _PDFNumPages; index++) {
                  list.push({ ..._image, ...{ pageNum: index + 1 } });
                }
                setSignatureImages([...list]);
              } else {
                editSignatureImage(_image);
              }
            });    
            event.target.removeAttribute("selected");
          },
        },
      });

    if (widgets.length > 0) {
      widgets.forEach((el) => {
        // if (Utils.is_touch_enabled()) {
        //   el.addEventListener("touchstart", (e) => {
        //     widgets.forEach((_el) => {
        //       _el.removeAttribute("selected")
        //     });
        //     setTimeout(() => {
        //       el.setAttribute("selected", true);
        //     }, 200);
        //   });
        // }else{
          el.addEventListener("mousedown", (e) => {
            widgets.forEach((_el) => {
              _el.removeAttribute("selected")
            });
            setTimeout(() => {
              el.setAttribute("selected", true);
            }, 200);
          });
        // }
      });      
    }

    if (document.querySelector(".signature_images")) {
      // if (Utils.is_touch_enabled()) {
      //   document.querySelector(".signature_images").addEventListener("touchstart", () => {
      //     widgets.forEach((el) => {
      //       el.removeAttribute("selected")
      //     });
      //   });
      // }else{
        document.querySelector(".signature_images").addEventListener("mousedown", () => {
          widgets.forEach((el) => {
            el.removeAttribute("selected")
          });
        });
      // }
    }
  };
}
