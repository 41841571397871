import {
  AUTH_CHECK,
  AUTH_STATE_DONE,
  AUTH_STATE_UPDATE,
  AUTH_TOKEN,
  SIGN_IN,
  SIGN_OUT,
  STATE_DONE,
  STATE_WAIT,
  USER_DATA,
  SET_PROFILE,
} from "../../constants";
import { _MainURL } from "../../services";

const authReducer = (state, action) => {
  switch (action.type) {
    case AUTH_CHECK:
      return {
        ...state,
        ...{
          authed: !!localStorage.getItem(AUTH_TOKEN) ? true : false,
          token: !!localStorage.getItem(AUTH_TOKEN)
            ? localStorage.getItem(AUTH_TOKEN)
            : null,
          session_id: !!localStorage.getItem('session_id')
            ? localStorage.getItem('session_id')
            : null,
          userData: !!localStorage.getItem(USER_DATA)
            ? JSON.parse(localStorage.getItem(USER_DATA))
            : null,
          state: STATE_DONE,
        },
      };

    case SIGN_IN:
      console.log("PYLD: ", action.payload);
      localStorage.setItem(AUTH_TOKEN, action.payload.token);
      localStorage.setItem("session_id", action.payload.session_id);
      return {
        authed: true,
        token: action.payload.token,
        session_id: action.payload.session_id,
        // userData: {
        //   name: action.payload.name || "",
        //   email: action.payload.email,
        // },
        state: STATE_DONE,
      };
    case SET_PROFILE:
      console.log("PYLD: ", action.payload);
      localStorage.setItem(USER_DATA, JSON.stringify(action.payload));
      return {
        ...state,
        userData: {
          name: action.payload.name || "",
          email: action.payload.email,
          signatures: action.payload.signatures,
          options: action.payload.options,
          title: action.payload.title,
        },
        state: STATE_DONE,
      };
    case AUTH_STATE_UPDATE:
      return {
        ...state,
        state: STATE_WAIT,
      };
    case AUTH_STATE_DONE:
      return {
        ...state,
        state: STATE_DONE,
      };
    case SIGN_OUT:
      localStorage.removeItem(AUTH_TOKEN);
      localStorage.removeItem(USER_DATA);
      localStorage.removeItem('session_id');
      window.location.href =
        _MainURL+"/v1/auth/sso/logout?session="+action.payload.session_id;
      return {
        authed: false,
        token: null,
        session_id: null,
        userData: null,
        state: STATE_DONE,
      };
    default:
      return state;
  }
};

export default authReducer;
