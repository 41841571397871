import React, { useContext, useEffect } from "react";
import "./App.scss";
import AppContext from "./contexts/appContext";
import { AUTH_CHECK } from "./constants";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import Auth from "./pages/auth";
import HomePage from "./pages/home";
import PDFApproval from "./pages/PdfApproval";
import LayoutAside from "./layout/layout-aside";
import ProfilePage from "./pages/profile";
import DRW2IMG from "./pages/drawtoimg";
// import history from "./history";

const ProtectedRoute = ({ auth }) => {
  return !!auth ? (
    <Outlet />
  ) : (
    <Navigate
      to={`/app/auth?redirect=${encodeURIComponent(window.location.pathname)}${encodeURIComponent(window.location.search)}`}
    />
  );
};
const App = () => {
  const {
    state: { auth },
    dispatch,
  } = useContext(AppContext);

  useEffect(() => {
    dispatch({ type: AUTH_CHECK });
    return () => {};
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/app" element={<ProtectedRoute auth={auth.authed} />}>
            <Route index element={<Navigate to="approvals" />} />
            <Route path="approvals">
              <Route
                index
                element={
                  <LayoutAside>
                    <HomePage />
                  </LayoutAside>
                }
              />
              <Route
                path="sign"
                element={<PDFApproval />}
              />
              <Route
                path="sign/:unique"
                element={<PDFApproval />}
              />
              <Route
                path="sign/:unique/:token"
                element={<PDFApproval />}
              />
            </Route>
            <Route
              path="profile"
              element={
                <LayoutAside>
                  <ProfilePage />
                </LayoutAside>
              }
            />
            <Route
              path="drw2img/:id"
              element={
                <LayoutAside>
                  <DRW2IMG />
                </LayoutAside>
              }
            />
            <Route path="*" element={<h1>404</h1>} />
          </Route>
          <Route path="/app/auth">
            <Route index element={<Auth />} />
            <Route path=":token" element={<Auth />} />
            <Route path="*" element={<h1>404</h1>} />
          </Route>
          <Route path="*" element={<Navigate to="/app" replace />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
