import { Box, Button, CircularProgress, Grid, Paper } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Trans } from "react-i18next";
import ApiServices from "../../services";
import AppContext from "../../contexts/appContext";
import SignatureSettings from "./signature";
import { AUTH_STATE_UPDATE, SET_PROFILE, STATE_WAIT } from "../../constants";
import handleError from "../../services/handleError";
import ProfileStamp from "./stamp";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ProfileSigImage from "./sig_image";
import SignaturePadTopaz from "./signature_pad_topaz";
import SignatureOptionsCard from "./sig_options";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ProfilePage = () => {
  const [tabValue, setTabValue] = React.useState(0);
  const [loading, setloading] = useState(false);
  const [isPad, setIspad] = useState(true);
  const {
    state: { auth },
    dispatch,
  } = useContext(AppContext);
  useEffect(() => {
    dispatch({
      type: AUTH_STATE_UPDATE,
    });

    setTabValue(isPad ? 2 : 0);
    if (!auth.token) {
      return;
    }
    getData();
    return () => {};
  }, []);
  const getData = () => {
    setloading(true);
    ApiServices.getProfileData({ token: auth.token })
      .then((res) => {
        console.log("===> List response: ", res);
        if (res.success === true) {
          setloading(false);
          dispatch({
            type: SET_PROFILE,
            payload: {
              name: res.payload.name,
              email: res.payload.email,
              signatures: res.payload.signatures,
              options: res.payload.options,
            },
          });
        } else {
          handleError(res.code, dispatch);
        }
      })
      .catch((err) => {
        console.error("===> Error response : ", err);
        setloading(false);
      });
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };
  
  return (
    <div style={{ padding: 16 }}>
      <h1 className="main-title">
        <Trans>sidenav.settings.profile</Trans>
      </h1>
      {loading&&auth.state == STATE_WAIT ? (
        <Box
          sx={{ display: "flex", justifyContent: "center", minHeight: "100vh" }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Paper
                        className="profile-card"
                        sx={{ width: "100%", height: "100%", p:3 }}
                      >
                        <h3 className="inner-title">Personal info</h3>
                        <Box className="form-input" sx={{mb:2, width: '100%'}}>
                          <label htmlFor="name">Name</label>
                          <input
                            disabled={true}
                            type="text"
                            name="name"
                            placeholder="Name"
                            defaultValue={auth?.userData?.name}
                          />
                        </Box>
                        <Box className="form-input" sx={{mb:2, width: '100%'}}>
                          <label htmlFor="email">Email</label>
                          <input
                            disabled={true}
                            type="email"
                            name="email"
                            placeholder="Email"
                            defaultValue={auth?.userData?.email}
                          />
                        </Box>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Paper
                          className="profile-card"
                          sx={{ width: "100%", height: "100%" }}
                        >
                        <h3 className="inner-title">Signature Options</h3>
                        <SignatureOptionsCard onSubmit={()=>getData()}/>
                      </Paper>                      
                    </Grid>
                  </Grid>
                <Box component="div" sx={{ mb: 2 }}></Box>
            </Grid>
          </Grid>
          <Box component="div" sx={{ mb: 2 }}></Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={9}>
              <Paper
                className="profile-card"
                sx={{ width: "100%", height: "100%" }}
              >
                <h3 className="inner-title">
                  <Trans>user_signatures</Trans>
                </h3>
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={tabValue}
                      onChange={handleChangeTab}
                      aria-label="basic tabs example"
                    >
                      <Tab label="Draw" {...a11yProps(0)} />
                      <Tab label="Upload image" {...a11yProps(1)} />
                      {isPad && <Tab label="Signature Pad" {...a11yProps(2)} />}
                    </Tabs>
                  </Box>
                  <TabPanel value={tabValue} index={0}>
                    <div className="form-input">
                      <SignatureSettings />
                    </div>
                  </TabPanel>
                  <TabPanel value={tabValue} index={1}>
                    <div className="form-input">
                      <ProfileSigImage />
                    </div>
                  </TabPanel>
                  {isPad && (
                    <TabPanel value={tabValue} index={2}>
                      <div className="form-input">
                        <SignaturePadTopaz />
                      </div>
                    </TabPanel>
                  )}
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3}>
              <Paper
                className="profile-card"
                sx={{ width: "100%", height: "100%" }}
              >
                <h3 className="inner-title">
                  <Trans>upload_stamp</Trans>
                </h3>
                <ProfileStamp />
              </Paper>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

export default ProfilePage;
