import { DeleteForever, UploadFile } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Fade,
  IconButton,
  Modal,
  TextField,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import ApiServices from "../../../services";
import Utils from "../../../services/utils";
import AppContext from "../../../contexts/appContext";
import handleError from "../../../services/handleError";

const ModalCreateApproval = ({ visible, cancel, onSubmit }) => {
  const [title, settitle] = useState("");
  const [description, setdescription] = useState("");
  const [file, setfile] = useState("");
  const [loading, setloading] = useState("");
  const { t } = useTranslation("translation");
  const {
    state: { auth },
    dispatch,
  } = useContext(AppContext);

  const modalStyle = {
    borderRadius: 8,
    width: "100%",
    maxWidth: '320px',
    p: 3,
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    overflowY: "auto",
    top: "16px",
    maxWith: "100%",
    bgcolor: "#f2f2f2",
    border: "2px solid #fff",
    boxShadow: 24,
    backgroundColor: "var(--light-color)",
    py: 4,
    px: 2
  };

  const handaleSubmit = () => {
    Utils.getBase64(file)
      .then((res) => {
        setloading(true);
        ApiServices.createApproval({
          token: auth.token,
          data: {
            title: title,
            description: description || "",
            document: res,
            document_name: file.name,
            valid_from: null,
            valid_to: null,
            one_by_one: true,
            ref_id: null,
            committee: null,
            send_emails: false,
            assignee: [
              {
                name: "",
                prefix: "",
                email: "__current__@ijsign.com",
              },
            ],
          },
        })
          .then((res) => {
            console.log("===> List response: ", res);
            if (res.success === true) {
              // setdata(res.payload);
              onSubmit();
              setloading(false);
            } else {
              setloading(false);
              handleError(res.code, dispatch);
            }
          })
          .catch((err) => {
            console.error("===> Error response : ", err);
            setloading(false);
          });
      })
      .catch((err) => console.log("Error: can't convert file to base 64"));
  };
  // file.name
  // size.size
  return (
    <Modal
      title={t("reject")}
      open={visible}
      className="ijtmaati-signature-modal"
      onClose={() => cancel()}
      closeAfterTransition
    >
      <Fade in={visible}>
        <Box sx={modalStyle}>
          <h2 style={{ margin: "0 0 32px" }}>
            <Trans>meetings.CreateButtons.Approvals</Trans>{" "}
            <Trans>meetings.CreateButtons.Create</Trans>
          </h2>
          <div className="content">
            <label
              htmlFor="title"
              style={{ textAlign: "start", display: "block" }}
            >
              Title
            </label>
            <input
              name="title"
              type="text"
              placeholder="title"
              onChange={(e) => {
                settitle(e.target.value);
              }}
            />
            <Box sx={{ mb: 2 }}></Box>
            <label
              style={{
                fontWeight: 600,
                fontSize: "14px",
                display: "block",
                marginBottom: "8px",
              }}
            >
              {t("meetings.create.Description")}
            </label>
            <textarea
              maxRows={6}
              onChange={(e) => {
                setdescription(e.target.value);
              }}
              value={description}
            ></textarea>
            <Box sx={{ mb: 2 }}></Box>
            <div className="files-wrapper">
              {file && (
                <div className="file-item">
                  <div className="info">
                    <h5 className="title">{file.name}</h5>
                    <span className="size">
                      {file.size.toString().length < 7
                        ? `${Math.round(+file.size.toString() / 1024).toFixed(
                            2
                          )}kb`
                        : `${(
                            Math.round(+file.size.toString() / 1024) / 1000
                          ).toFixed(2)}MB`}
                    </span>
                  </div>
                  <IconButton
                    color="error"
                    aria-label="delete"
                    onClick={() => setfile("")}
                  >
                    <DeleteForever />
                  </IconButton>
                </div>
              )}
              <Button
                disableElevation
                variant="contained"
                component="label"
                className="upButton"
                startIcon={<UploadFile />}
              >
                Upload File
                <input
                  type="file"
                  hidden
                  accept=".pdf"
                  onChange={(e) => setfile(e.target.files[0])}
                />
              </Button>
            </div>
            <Box
              sx={{
                mb: 2,
                pb: 2,
                borderBottom: "1px solid var(--border-color)",
              }}
            ></Box>
          </div>
          <div style={{ marginTop: "16px", display: "flex", gap: "10px" }}>
            <Button
              variant="outlined"
              className={"px-4 ml-2"}
              onClick={() => {
                cancel();
              }}
            >
              <Trans>close</Trans>
            </Button>
            <Button
              color="primary"
              disabled={!!title && !!file ? false : true}
              variant="contained"
              className="cancel"
              onClick={handaleSubmit}
            >
              {loading ? (
                <CircularProgress
                  color="inherit"
                  style={{
                    width: "18px",
                    height: "18px",
                    marginInlineEnd: "6px",
                  }}
                />
              ) : (
                <></>
              )}
              <Trans>Submit</Trans>
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ModalCreateApproval;
