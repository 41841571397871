import React, { useContext, useEffect } from "react";
import AppContext from "../contexts/appContext";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { LOGO_IMG, SIGN_OUT } from "../constants";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";
import { NavLink } from "react-router-dom";
import { Logout } from "@mui/icons-material";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(6)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(6)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const LayoutAside = ({ children }) => {
  const { dispatch } = useContext(AppContext);
  const { t } = useTranslation();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  useEffect(() => {
      if (document.body.clientWidth < 990) {
        setOpen(false);
      }
    return () => {};
  }, []);

  useEffect(() => {
    window.onresize = ()=>{
      if (document.body.clientWidth < 990) {
        if (!!open) {
          setOpen(false);
        }
      }else{
        if (!open) {
          setOpen(true);
        }
      }
    };
    return () => {};
  }, [document.body.clientWidth]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const {
    state: { auth },
  } = useContext(AppContext);
  const handleSignOut = () => {
    dispatch({
      type: SIGN_OUT,
      payload: { session_id: auth.session_id },
    });
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {false && (
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <Typography variant="h6" noWrap component="div">
              Mini variant drawer
            </Typography>
          </Toolbar>
        </AppBar>
      )}
      <Drawer 
      variant="permanent"
      // variant= "persistent"
      open={open}>
        <DrawerHeader className={styles.asideHeader}>
          {open && (
            <div className={styles.imgLogo}>
              <LOGO_IMG />
            </div>
          )}
          {!open && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
            >
              <MenuIcon />
            </IconButton>
          )}
          {open && (
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          )}
        </DrawerHeader>
        <Divider />
        <List
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <NavLink to="/app/approvals" className="nav-link">
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                sm={{
                  px: 1,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <BorderColorIcon />
                </ListItemIcon>
                <ListItemText
                  primary={t("sidenav.activity.approvals")}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </NavLink>
          <NavLink to="/app/profile" className="nav-link">
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <ManageAccountsIcon />
                </ListItemIcon>
                <ListItemText
                  primary={t("sidenav.settings.profile")}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </NavLink>
          {/* <NavLink to="/app/drw2img" className="nav-link">
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <ManageAccountsIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Drw2Img"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </NavLink>           */}
          <div
            className="nav-link"
            onClick={handleSignOut}
            style={{
              marginTop: "auto",
              borderTop: "1px solid var(--border-color)",
            }}
          >
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Logout />
                </ListItemIcon>
                <ListItemText
                  primary={t("auth.sign_out")}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </div>
        </List>
      </Drawer>
      <Box
        className={styles.mainContent}
        component="main"
        sx={{
          flexGrow: 1,
          p: 0,
          width: `${
            open
              ? `calc(100vw - ${drawerWidth}px - 8px)`
              : `calc(100vw - (${theme.spacing(7)} + 1px) - 8px)`
          }`,
        }}
      >
        {/* <DrawerHeader /> */}
        {children}
      </Box>
    </Box>
  );
};

export default LayoutAside;
