import { Trans, useTranslation } from "react-i18next";
import { useContext, useEffect, useRef, useState } from "react";
import SignaturePad from "react-signature-canvas";
import {
  Button,
  CircularProgress,
  Divider,
  Fade,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Modal,
  Paper,
  Switch,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { PhotoCamera } from "@mui/icons-material";
import AppContext from "../../contexts/appContext";
import { STATE_DONE } from "../../constants";
import { _URL_ASSETS } from "../../services";
import Utils from "../../services/utils";

const getBase64 = (file) => {
  return new Promise((resolve) => {
    let baseURL = "";
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object

      baseURL = reader.result;

      resolve(baseURL);
    };
  });
};

const ModalContent = ({ cancel, addStamp }) => {
  const {
    state: { auth },
  } = useContext(AppContext);
  const [fakeLoader, setfakeLoader] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setfakeLoader(false);
    }, 200);
    return () => {
      setfakeLoader(true);
    };
  }, []);
  const { t } = useTranslation("translation");
  const [myStamp, setMyStamp] = useState("");

  const handaleSubmitt = () => {
    addStamp(myStamp);
    cancel();
  };

  const handaleCancel = () => {
    cancel();
  };
  const handaleClear = () => {
    setMyStamp("");
  };
  const handleUploadChange = (e) => {
    // console.log('e: ', e.target.files);
    let file = e.target.files[0];

    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        setMyStamp(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleSelectSavedImage = (item) => {
    let file = _URL_ASSETS + item;
    Utils.getBase64FromUrl(file)
      .then((result) => {
        // console.log('RESULT: ', result);
        setMyStamp(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="ijtmaati-signature-modal-content" style={{ paddingTop: 0 }}>
      <div className="draw-container">
        <div className="draw-container-heading"></div>
        <div
          className="draw-area"
          style={{
            minWidth: 300,
            display: "flex",
            justifyContent: "center",
            marginBottom: 32,
            marginTop: 0,
          }}
        >
          {!fakeLoader && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                marginBottom: 32,
              }}
            >
              <Button
                variant="contained"
                disableElevation
                color="primary"
                aria-label="upload picture"
                component="label"
                startIcon={<PhotoCamera />}
                style={{ marginBottom: 16, alignSelf: "flex-start" }}
              >
                <Trans>upload_stamp</Trans>
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  onChange={handleUploadChange}
                />
              </Button>

              {myStamp ? (
                <img
                  src={myStamp}
                  style={{
                    minWidth: 300,
                    maxHeight: 200,
                    maxWidth: "100%",
                    marginBottom: 16,
                    objectFit: "contain",
                    backgroundColor: "var(--light-color)",
                    padding: 16,
                    borderRadius: 8,
                  }}
                />
              ) : (
                <Box
                  sx={{
                    minWidth: 300,
                    maxHeight: 200,
                    maxWidth: "100%",
                    marginBottom: 2,
                    objectFit: "contain",
                    backgroundColor: "var(--light-color)",
                    padding: 16,
                    borderRadius: 8,
                    // boxShadow: 'none'
                  }}
                ></Box>
              )}
              <Divider/>
              {auth?.userData?.signatures && (
                <p className="inner-title" style={{ margin: 0 ,marginTop: 16, }}>
                  <Trans>user_stamps</Trans>
                </p>
              )}
              <div
                className="signatures_preview_list"
                style={{ marginTop: 16, paddingTop: 16 }}
              >
                {auth.state === STATE_DONE ? (
                  <Grid container spacing={2} sx={{ minWidth: "400px" }}>
                    {auth?.userData?.signatures
                      .filter((f) => f.type === "stamp")
                      .map((item, idx) => (
                        <Grid item xs={12} sm={6} md={5} lg={4} key={idx}>
                          <Paper
                            sx={{
                              height: "100%",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                            elevation={0}
                            onClick={() => handleSelectSavedImage(item.payload)}
                          >
                            <img
                              src={_URL_ASSETS + item.payload}
                              style={{
                                maxWidth: "100%",
                                maxHeight: 100,
                                objectFit: "contain",
                                padding: 16,
                                marginBottom: 8,
                                objectFit: "contain",
                                margin: "auto",
                              }}
                            />
                            <h4
                              className="title"
                              style={{
                                margin: "auto 0 0",
                                padding: "4px 8px",
                                fontSize: 14,
                              }}
                            >
                              {item.title}
                            </h4>
                          </Paper>
                        </Grid>
                      ))}
                  </Grid>
                ) : (
                  <div style={{ padding: 32 }}>
                    <CircularProgress
                      color="inherit"
                      style={{
                        width: "18px",
                        height: "18px",
                        marginInlineEnd: "6px",
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="footer-btn">
        <Button className={"px-4 ml-2"} onClick={handaleCancel}>
          <Trans>tour.close</Trans>
        </Button>
        <Button
          variant="contained"
          className={"px-4 ml-2"}
          onClick={handaleSubmitt}
          disabled={myStamp !== "" ? false : true}
        >
          <Trans>submit</Trans>
        </Button>
      </div>
    </div>
  );
};

export default function PDFModalStamp({ visible, cancel, addStamp }) {
  // reject, sign
  const { t } = useTranslation("translation");
  const modalStyle = {
    position: "absolute",
    // top: "50%",
    left: "50%",
    transform: "translateX(-50%)",
    // width: 400,
    overflowY: "auto",
    top: "16px",
    bgcolor: "#f2f2f2",
    border: "2px solid #fff",
    boxShadow: 24,
    p: 4,
  };
  return (
    <Modal
      title={t("upload_stamp")}
      open={visible}
      className="ijtmaati-signature-modal"
      onClose={() => cancel()}
      closeAfterTransition
    >
      <Fade in={visible}>
        <Box sx={modalStyle} className="modal-box">
          <h2 className="modal-title" style={{ margin: "0 0 16px" }}>
            <Trans>upload_stamp</Trans>
          </h2>
          <ModalContent cancel={cancel} addStamp={addStamp} />
        </Box>
      </Fade>
    </Modal>
  );
}
