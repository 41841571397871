import React, { useContext, useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Trans } from "react-i18next";
import Utils from "../../../services/utils";
import { Backdrop, Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import handleError from "../../../services/handleError";
import ApiServices, { _URL_ASSETS } from "../../../services";
import { SET_PROFILE } from "../../../constants";
import AppContext from "../../../contexts/appContext";
const ProfileSigImage = () => {
  const [myImage, setMyImage] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const {
    state: { auth },
    dispatch,
  } = useContext(AppContext);

  const handleUploadChange = (e) => {
    // console.log('e: ', e.target.files);
    let file = e.target.files[0];

    Utils.getBase64(file)
      .then((result) => {
        file["base64"] = result;
        setMyImage({ ...myImage, ...{ payload: result } });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getData = () => {
    ApiServices.getProfileData({ token: auth.token })
      .then((res) => {
        if (res.success === true) {
          dispatch({
            type: SET_PROFILE,
            payload: {
              name: res.payload.name,
              email: res.payload.email,
              signatures: res.payload.signatures,
              options: res.payload.options,
            },
          });
        } else {
          handleError(res.code, dispatch);
        }
      })
      .catch((err) => {
        console.error("===> Error response : ", err);
      });
  };
  const handleAddImage = () => {
    let _signature = {};
    _signature.title = myImage.title;
    _signature.type = "signature_image";
    _signature.payload = myImage.payload;

    setSaveLoading(true);
    ApiServices.editProfileData({
      token: auth.token,
      data: { signature: _signature },
    })
      .then((res) => {
        setSaveLoading(false);
        console.log("===> List response: ", res);
        if (res.success === true) {
          getData();
          setMyImage("");
        } else {
          handleError(res.code, dispatch);
        }
      })
      .catch((err) => {
        console.error("===> Error response : ", err);
        setSaveLoading(false);
      });
  };
  const handleDeleteImage = (_id) => {
    setDeleteLoading(true);
    ApiServices.deleteSignature({
      token: auth.token,
      id: _id,
    })
      .then((res) => {
        setDeleteLoading(false);
        if (res.success === true) {
          getData();
        } else {
          handleError(res.code, dispatch);
        }
      })
      .catch((err) => {
        console.error("===> Error response : ", err);
        setDeleteLoading(false);
      });
  };
  
  return (
    <div className="signature-settings">
      <Box className="ijtmaati-signature-modal" sx={{ p: 3,alignItems: 'flex-start',justifyContent: 'flex-start',display: 'flex',flexDirection: 'column' }}>
        <Box className="input-group" sx={{ mb: 2 }}>
          <label htmlFor="title" style={{ textAlign: "start", display: "block",  }}>
            Signature name
          </label>
          <input
            name="title"
            type="text"
            placeholder="Signature name"
            value={myImage?.title || ""}
            onChange={(e) =>
              setMyImage({
                ...myImage,
                ...{ title: e.target.value },
              })
            }
          />
        </Box>

        <label htmlFor="ImageFile" className="add-signature-box">
          <AddIcon />
          <Trans>upload_Image</Trans>
          {!!myImage && !!myImage.payload && (
            <img src={myImage?.payload} className="img" />
          )}
        </label>
        <input
          type="file"
          name="ImageFile"
          id="ImageFile"
          onChange={handleUploadChange}
          style={{
            width: 0,
            height: 0,
            overflow: "hidden",
            padding: 0,
            position: "relative",
            zIndex: -1,
            transform: "translateY(-20px)",
          }}                              
        />
        <Button
          color="primary"
          disableElevation
          disabled={
            !!myImage && !!myImage.payload && !!myImage.title ? false : true
          }
          variant="contained"
          className="cancel"
          onClick={handleAddImage}
        >
          {saveLoading ? (
            <CircularProgress
              color="inherit"
              style={{
                width: "18px",
                height: "18px",
                marginInlineEnd: "6px",
              }}
            />
          ) : (
            <Add />
          )}
          <Trans>save</Trans>
        </Button>

        {/* ?.filter((f) => f.type === "signature_image") */}

        <div className="stamps-list">
          <Grid container spacing={2}>
            {true && auth?.userData?.signatures
              ?.filter((f) => f.type != "signature")
              .map((item, idx) => (
                <Grid item xs={12} sm={4} md={3} lg={4} key={idx}>
                  <div className="item-stamp">
                    <img src={Utils.parseImage(item)} alt="." className="img" />
                    {/* {
                      item.payload.includes(".png")?
                      <img src={_URL_ASSETS + item.payload} alt="." className="img" />
                      :
                      <img src={Utils.decryptData(JSON.parse(item.payload))} alt="." className="img" />
                    } */}
                    <Typography component="h4" className="title">
                      {item.title}
                    </Typography>
                    <Button
                      color="error"
                      disableElevation
                      variant="outlined"
                      onClick={() => handleDeleteImage(item.id)}
                      sx={{ mt: 1 }}
                    >
                      <Delete />
                      {/* <Trans>general.delete</Trans> */}
                    </Button>
                  </div>
                </Grid>
              ))}
          </Grid>
        </div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={deleteLoading}
          onClick={()=>{}}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </div>
  );
};

export default ProfileSigImage;
