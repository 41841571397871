import React, { useContext, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Trans } from "react-i18next";
import Utils from "../../../services/utils";
import { Backdrop, Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import handleError from "../../../services/handleError";
import ApiServices, { _URL_ASSETS } from "../../../services";
import { SET_PROFILE } from "../../../constants";
import AppContext from "../../../contexts/appContext";
const ProfileStamp = () => {
  const [myStamp, setMyStamp] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const {
    state: { auth },
    dispatch,
  } = useContext(AppContext);

  const handleUploadChange = (e) => {
    // console.log('e: ', e.target.files);
    let file = e.target.files[0];

    Utils.getBase64(file)
      .then((result) => {
        file["base64"] = result;
        setMyStamp({ ...myStamp, ...{ payload: result } });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getData = () => {
    ApiServices.getProfileData({ token: auth.token })
      .then((res) => {
        if (res.success === true) {
          dispatch({
            type: SET_PROFILE,
            payload: {
              name: res.payload.name,
              email: res.payload.email,
              signatures: res.payload.signatures,
              options: res.payload.options,
            },
          });
        } else {
          handleError(res.code, dispatch);
        }
      })
      .catch((err) => {
        console.error("===> Error response : ", err);
      });
  };
  const handleAddStamp = () => {
    let _signature = {};
    _signature.title = myStamp.title;
    _signature.type = "stamp";
    _signature.payload = JSON.stringify(myStamp.payload);

    setSaveLoading(true);
    ApiServices.editProfileData({
      token: auth.token,
      data: { signature: _signature },
    })
      .then((res) => {
        setSaveLoading(false);
        console.log("===> List response: ", res);
        if (res.success === true) {
          getData();
          setMyStamp("");
        } else {
          handleError(res.code, dispatch);
        }
      })
      .catch((err) => {
        console.error("===> Error response : ", err);
        setSaveLoading(false);
      });
  };
  const handleDeleteStamp = (_id) => {
    setDeleteLoading(true);
    ApiServices.deleteSignature({
      token: auth.token,
      id: _id,
    })
      .then((res) => {
        setDeleteLoading(false);
        if (res.success === true) {
          getData();
        } else {
          handleError(res.code, dispatch);
        }
      })
      .catch((err) => {
        console.error("===> Error response : ", err);
        setDeleteLoading(false);
      });
  };

  return (
    <>
      <Box className="input-group" sx={{ mb: 2 }}>
        <label htmlFor="title" style={{ textAlign: "start", display: "block" }}>
          Title
        </label>
        <input
          name="title"
          type="text"
          placeholder="title"
          value={myStamp?.title || ""}
          onChange={(e) =>
            setMyStamp({
              ...myStamp,
              ...{ title: e.target.value },
            })
          }
        />
      </Box>

      <label htmlFor="stampFile" className="add-signature-box">
        <AddIcon />
        <Trans>upload_stamp</Trans>
        {!!myStamp && !!myStamp.payload && (
          <img src={myStamp?.payload} className="img" />
        )}
      </label>
      <input
        type="file"
        name="stampFile"
        id="stampFile"
        onChange={handleUploadChange}
        style={{
          width: 0,
          height: 0,
          overflow: "hidden",
          padding: 0,
          position: "relative",
          zIndex: -1,
          transform: "translateY(-20px)",
        }}
      />
      <Button
        color="primary"
        disableElevation
        disabled={
          !!myStamp && !!myStamp.payload && !!myStamp.title ? false : true
        }
        variant="contained"
        className="cancel"
        onClick={handleAddStamp}
      >
        {saveLoading ? (
          <CircularProgress
            color="inherit"
            style={{
              width: "18px",
              height: "18px",
              marginInlineEnd: "6px",
            }}
          />
        ) : (
          <Add />
        )}
        <Trans>save</Trans>
      </Button>

      <div className="stamps-list">
        <Grid container spacing={2}>
          {auth?.userData?.signatures
            ?.filter((f) => f.type === "stamp")
            .map((item, idx) => (
              <Grid item xs={6} sm={4} md={3} lg={12} key={idx}>
                <div className="item-stamp">
                  <img src={_URL_ASSETS + item.payload} alt="" className="img" />                  
                  <Typography component="h4" className="title">
                    {item.title}
                  </Typography>
                  <Button
                    color="error"
                    disableElevation
                    variant="outlined"
                    onClick={() => handleDeleteStamp(item.id)}
                    sx={{ mt: 1 }}
                  >
                    <Delete />
                    {/* <Trans>general.delete</Trans> */}
                  </Button>
                </div>
              </Grid>
            ))}
        </Grid>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={deleteLoading}
        onClick={()=>{}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default ProfileStamp;
