import { Lens } from "@mui/icons-material";
import {
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  colors,
} from "@mui/material";
import * as htmlToImage from "html-to-image";
import React, { useEffect, useRef, useState } from "react";
import styles from "./TextSignature.module.scss";
import { Trans, useTranslation } from "react-i18next";

const TextSignature = ({ setSignature }) => {
  const colors = ["#000000", "#2293FB", "#ec0f0f"];
  const fontsiz = [
    {
      txt: "Small",
      val: 48,
    },
    {
      txt: "Normal",
      val: 68,
    },
    {
      txt: "Large",
      val: 88,
    },
    {
      txt: "Extra large",
      val: 110,
    },
  ];
  const fontfam = [
    {
      txt: "Alison",
      val: "'Allison', cursive",
    },
    {
      txt: "Ephesis",
      val: "'Ephesis', cursive",
    },
    {
      txt: "Hurricane",
      val: "'Hurricane', cursive",
    },
  ];

  const { t } = useTranslation();
  const imgRef = useRef();
  const [name, setname] = useState("");
  const [loadingImg, setLoadingImg] = useState(false);
  const [activeColor, setactiveColor] = useState(colors[0]);
  const [activeSize, setactiveSize] = useState(fontsiz[1].val);
  const [activeFont, setactiveFont] = useState(fontfam[0].val);

  const onSave = () => {

  };

  useEffect(() => {
    // updateDetails({ color: activeColor, font: activeFont, size: activeSize });
    if (!name) return;
    let timer = setTimeout(() => {
        setLoadingImg(true);
        htmlToImage
          .toPng(imgRef.current)
          .then(function (dataUrl) {
            // console.log("IMAGE RESULT: ", dataUrl);
            setSignature({ src: dataUrl, tab: 'text_signature', });
            setLoadingImg(false);
            // settmpImage(dataUrl);
          })
          .catch(function (error) {
            setLoadingImg(false);
            console.error("oops, something went wrong!", error);
          });
    }, 1000);
    return () => clearTimeout(timer);
  }, [name, activeColor, activeFont, activeSize]);

  return (
    <div>
      <div className={styles["inputs-area"]}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <FormControl className={styles.FormControl} fullWidth>
              <label>
                <Trans>approval_sign</Trans>
              </label>
              <input
                type="text"
                placeholder={t("approval_sign")}
                onChange={(e) => setname(e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={2} md={1} lg={1}>
            <FormControl className={styles.FormControl} fullWidth>
              <label>Color</label>
              <Select
                className={styles.selectInput}
                id="select"
                value={activeColor}
                onChange={(e) => setactiveColor(e.target.value)}
              >
                {colors.map((c) => (
                  <MenuItem value={c}>
                    <Lens style={{ color: c, fontSize: 14 }} />{" "}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={2} md={3} lg={3}>
            <FormControl className={styles.FormControl} fullWidth>
              <label>Size</label>
              <Select
                className={styles.selectInput}
                id="select"
                value={activeSize}
                onChange={(e) => setactiveSize(e.target.value)}
              >
                {fontsiz.map((f) => (
                  <MenuItem value={f.val} style={{ fontSize: f.val / 3 }}>
                    {f.txt}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <FormControl className={styles.FormControl} fullWidth>
              <label>Font family</label>
              <Select
                className={styles.selectInput}
                id="select"
                value={activeFont}
                onChange={(e) => setactiveFont(e.target.value)}
              >
                {fontfam.map((f) => (
                  <MenuItem value={f.val}>
                    <div style={{ fontFamily: f.val, fontSize: 24 }}>
                      {f.txt}
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* <Grid item xs={12} sm={2} md={2} lg={1}>
            <FormControl className={styles.FormControl} fullWidth>
              <Button
                style={{ marginTop: 29 }}
                type="button"
                variant="outlined"
                onClick={onSave}
              >
                <Trans>save</Trans>
              </Button>
            </FormControl>
          </Grid> */}
        </Grid>
      </div>
      <div className={styles.signatureDiv}>
        <p className={styles.prevDv}>Preview</p>
        <div
          ref={imgRef}
          className={styles.signature}
          style={{
            color: activeColor,
            fontSize: activeSize,
            fontFamily: activeFont,
          }}
        >
          {name}
        </div>
      </div>
      <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loadingImg}
          onClick={()=>{}}
        >
          <CircularProgress color="inherit" />
      </Backdrop>      
    </div>
  );
};

export default TextSignature;
