import React, { useContext, useEffect, useState } from "react";
import { Trans } from "react-i18next";
import ApprovalsTable from "./table";
import { Box, Button, CircularProgress } from "@mui/material";
import ApiServices from "../../services";
import AppContext from "../../contexts/appContext";
import handleError from "../../services/handleError";
import { Add } from "@mui/icons-material";
import ModalCreateApproval from "./modal_create_approval";

export default function HomePage() {
  const [data, setdata] = useState([]);
  const [showCreateApproval, setShowCreateApproval] = useState(false);
  const [loading, setloading] = useState(false);
  const {
    state: { auth },
    dispatch
  } = useContext(AppContext);
  useEffect(() => {
    if (!auth.token) {
      return;
    }
    setloading(true);
    ApiServices.getApprovalsList({ token: auth.token })
      .then((res) => {
        console.log("===> List response: ", res);
        if (res.success === true) {
          setdata(res.payload);
          setloading(false);
        } else {
          setloading(false);
          handleError(res.code, dispatch);
        }
      })
      .catch((err) => {
        console.error("===> Error response : ", err);
        setloading(false);
      });
    return () => {};
  }, []);
  const addApproval = () => {
    setShowCreateApproval(false);
    setloading(true);
    ApiServices.getApprovalsList({ token: auth.token })
      .then((res) => {
        console.log("===> List response: ", res);
        if (res.success === true) {
          setdata(res.payload);
          setloading(false);
        } else {
          setloading(false);
          handleError(res.code, dispatch);
        }
      })
      .catch((err) => {
        console.error("===> Error response : ", err);
        setloading(false);
      });
  };
  return (
    <div style={{ padding: 16 }}>
      <div className="main-title-wrapper" style={{marginBottom: '32px'}}>
        <h1 className="main-title" style={{margin: 0}}>
          <Trans>sidenav.activity.approvals</Trans>
        </h1>
        <Button
          className="create_approval_btn"
          variant="contained"
          color="primary"
          aria-label="upload file"
          component="label"
          startIcon={<Add />}
          style={{
            boxShadow: "none",
            alignSelf: "center",
            marginInlineStart: "auto",
          }}
          onClick={() => setShowCreateApproval(true)}
        >
          <span className="text"><Trans>meetings.CreateButtons.Create</Trans>{" "}<Trans>meetings.CreateButtons.Approvals</Trans></span>
        </Button>
      </div>
      <>
        {showCreateApproval && (
          <ModalCreateApproval
            visible={showCreateApproval}
            cancel={() => setShowCreateApproval(false)}
            onSubmit={addApproval}
          />
        )}
      </>
      {loading ? (
        <Box
          sx={{ display: "flex", justifyContent: "center", minHeight: "100vh" }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <ApprovalsTable data={data} />
      )}
    </div>
  );
}
