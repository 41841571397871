import CryptoJS from 'crypto-js'
import { _URL_ASSETS } from '.';

const Utils = {};
Utils.getBase64 = (file) => {
  return new Promise((resolve) => {
    let baseURL = "";
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object

      baseURL = reader.result;

      resolve(baseURL);
    };
  });
};
Utils.getBase64FromUrl = async (url) => {
  const data = await fetch(url,{headers: {'Content-Type': 'image/png'}});
  const blob = await data.blob();
  return new Promise((resolve) => {
    const newBlob = new Blob([blob], {type: 'image/png'}); // create a new blob with the image/png type    
    const reader = new FileReader();
    reader.readAsDataURL(newBlob);
    reader.onloadend = () => {
      const base64data = reader.result;
      console.log('==> Reader: ', reader);
      resolve(base64data);
    };
  });
};

Utils.encryptData =  (_string) => {
  const data = CryptoJS.AES.encrypt(
    JSON.stringify(_string),
    'XkhZG4fW2t2W'
    // process.env.secretPass
  ).toString();

  return data;
};

Utils.decryptData =  (_string) => {
  if (_string.includes(".png")) {
    return 'dsf';
  }
  const bytes = CryptoJS.AES.decrypt(
    _string, 
    'XkhZG4fW2t2W'
    // process.env.secretPass
  );
  const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return data;
};

Utils.parseImage = (item)=>{
  let _payload = item.payload_image?item.payload_image:item.payload;
  if (_payload.includes(".png")) {
    return _URL_ASSETS + _payload;
  }
  if (_payload.includes("data:image/png;base64")) {
    return _payload;
  }
  if (!_payload.includes(".png")) {
    let result = '';
    try {
      result = Utils.decryptData(JSON.parse(_payload));
    } catch (error) {
      result = Utils.decryptData(_payload);
    }
    return result;
  }
}

Utils.is_touch_enabled = () => {
  return ( 'ontouchstart' in window ) || ( navigator.maxTouchPoints > 0 ) || ( navigator.msMaxTouchPoints > 0 );
}

export default Utils;
