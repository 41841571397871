import {
  Box,
  Button,
  Fade,
  FormControlLabel,
  FormGroup,
  Modal,
  Switch,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const AppearanceModal = ({ visible, cancel, onSubmit, activeSignature, appearanceOptions }) => {
  const { t } = useTranslation("translation");
  const [options, setOptions] = React.useState(activeSignature?activeSignature.options:appearanceOptions);
  // const [openDialog, setOpenDialog] = React.useState(false);
  // const [applyToAll, setApplyToAll] = React.useState(true);

  const modalStyle = {
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    overflowY: "auto",
    top: "16px",
    minWidth: "300px",
    maxWith: "100%",
    bgcolor: "var(--light-color)",
    border: "2px solid #fff",
    boxShadow: 24,
    pt: 1,
    pb: 3,
    pr: 2,
    pl: 2,
  };

  const handaleSubmit = () => {
    onSubmit(options, true);
    cancel();
  };
  const handleChange = (event) => {
    setOptions({
      ...options,
      [event.target.name]: event.target.checked,
    });
  };
  // const handleApplyToAll = (e) => {
  //   if (!!e.target.checked) {
  //     setOpenDialog(true);
  //   } else {
  //     setApplyToAll(e.target.checked);
  //   }
  // };
  // const handleApplyDialog = () => {
  //   setApplyToAll(true);
  //   setOpenDialog(false);
  // };
  // const handleClose = () => {
  //   setOpenDialog(false);
  // };

  return (
    <Modal
      title={t("reject")}
      open={visible}
      onClose={() => cancel()}
      closeAfterTransition
    >
      <Fade in={visible}>
        <Box sx={modalStyle} className="modal-box">
          <h2 style={{ margin: "0 0 16px" }}>
            <Trans>appearance</Trans>
          </h2>
          <div
            className="content"
            style={{ backgroundColor: "var(--bg-color)", padding: 8 }}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={options.displayOnBottom}
                    onChange={handleChange}
                    name="displayOnBottom"
                  />
                }
                label={
                  options.displayOnBottom ? "Position Bottom" : "Position Right"
                }
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={options.showName}
                    onChange={handleChange}
                    name="showName"
                  />
                }
                label="Show name"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={options.showDate}
                    onChange={handleChange}
                    name="showDate"
                  />
                }
                label="Show Date"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={options.showReason}
                    onChange={handleChange}
                    name="showReason"
                  />
                }
                label="Show Reason"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={options.jobtitle}
                    onChange={handleChange}
                    name="jobtitle"
                  />
                }
                label="Show title"
              />
            </FormGroup>
          </div>
          {/* <div
            className="content"
            style={{
              backgroundColor: "var(--bg-color)",
              padding: 8,
              marginTop: 16,
            }}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={applyToAll}
                    onChange={handleApplyToAll}
                    name="displayOnBottom"
                  />
                }
                label="Apply to all signature"
              />
            </FormGroup>
          </div> */}
          <div style={{ marginTop: 16, display: "flex", gap: "10px" }}>
            <Button
              variant="outlined"
              className={"px-4 ml-2"}
              onClick={() => {
                cancel();
              }}
            >
              <Trans>close</Trans>
            </Button>
            <Button
              color="primary"
              variant="contained"
              className="cancel"
              onClick={handaleSubmit}
            >
              <Trans>submit</Trans>
            </Button>
          </div>

          {/* <Dialog
            open={openDialog}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Are you sure about that decision?
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Be careful, it will apply to all signatures in the entire file
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button color="error" onClick={handleClose}>
                Disagree
              </Button>
              <Button color="primary" onClick={handleApplyDialog} autoFocus>
                Agree
              </Button>
            </DialogActions>
          </Dialog> */}
        </Box>
      </Fade>
    </Modal>
  );
};

export default AppearanceModal;
