import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./lang/i18n";
import { ThemeProvider, createTheme } from "@mui/material";
import { AppProvider } from "./contexts/appContext";
const theme = createTheme({
  palette: {
    primary: {
      main: "#fec20e",
      contrastText: '#fff',
    },
    error: {
      main: "#f33e3e",
      contrastText: '#fff',
    },
  },
});
const root = ReactDOM.createRoot(
  document.getElementById("root")
);
root.render(
  <React.StrictMode>
    <AppProvider>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </AppProvider>
  </React.StrictMode>
);
