import { Backdrop, Button, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Trans } from "react-i18next";
import SignaturePad from "react-signature-canvas";
import SignaturePreview from "../../../components/SignaturePreview";
import ApiServices, { _URL_ASSETS } from "../../../services";
import AppContext from "../../../contexts/appContext";
import handleError from "../../../services/handleError";
import { AUTH_STATE_UPDATE, SET_PROFILE, STATE_DONE } from "../../../constants";
import {
  Add,
  AutoFixNormal,
  Close,
  Delete,
  Edit,
  RestartAlt,
} from "@mui/icons-material";
import Utils from "../../../services/utils";

const SignatureSettings = () => {
  const [fakeLoader, setfakeLoader] = useState(true);
  const [mySignature, setMySignature] = useState("");
  const [title, setTitle] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const {
    state: { auth },
    dispatch,
  } = useContext(AppContext);
  const sigCan = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      setfakeLoader(false);
    }, 100);

    return () => {};
  }, []);
  useEffect(() => {
    console.log("MySignature: ", mySignature);
    if (mySignature && mySignature?.payload?.length > 0) {
      setTimeout(() => {
        sigCan.current.fromData(
          mySignature.payload.map((path) =>
            path.map((point) => ({ x: point.x, y: point.y, color: "#000000" }))
          )
        );
      }, 300);
    }
    return () => {};
  }, [mySignature]);

  const handleClear = () => {
    sigCan?.current?.clear();
    setMySignature({ ...mySignature, payload: null });
  };
  const handleAddSignature = () => {
    let _signature = {};
    _signature.title = mySignature.title;
    _signature.type = "signature";
    _signature.payload = JSON.stringify(mySignature.payload);
    _signature.payload_image = mySignature.payloadImg;

    setSaveLoading(true);
    ApiServices.editProfileData({
      token: auth.token,
      data: { signature: _signature },
    })
      .then((res) => {
        setSaveLoading(false);
        console.log("===> List response: ", res);
        if (res.success === true) {
          getData();
          sigCan?.current?.clear();
          setMySignature("");
        } else {
          handleError(res.code, dispatch);
        }
      })
      .catch((err) => {
        console.error("===> Error response : ", err);
        setSaveLoading(false);
      });
  };
  const handleEditSignature = () => {
    let _signature = {};
    _signature.title = mySignature.title;
    _signature.type = "signature";
    _signature.payload = mySignature.payload;

    setSaveLoading(true);
    ApiServices.editSignature({
      token: auth.token,
      data: { signature: _signature },
      id: mySignature.id,
    })
      .then((res) => {
        setSaveLoading(false);
        console.log("===> List response: ", res);
        if (res.success === true) {
          dispatch({ type: AUTH_STATE_UPDATE });
          getData();
          sigCan?.current?.clear();
          setMySignature("");
        } else {
          handleError(res.code, dispatch);
        }
      })
      .catch((err) => {
        console.error("===> Error response : ", err);
        setSaveLoading(false);
      });
  };

  const handleConvertSignature = () => {
    let _signature = {};
    _signature.title = mySignature.title;
    _signature.type = "signature";
    _signature.payload = mySignature.payload;

    setSaveLoading(true);
    ApiServices.editSignature({
      token: auth.token,
      data: { signature: _signature },
      id: mySignature.id,
    })
      .then((res) => {
        setSaveLoading(false);
        console.log("===> List response: ", res);
        if (res.success === true) {
          dispatch({ type: AUTH_STATE_UPDATE });
          getData();
          sigCan?.current?.clear();
          setMySignature("");
        } else {
          handleError(res.code, dispatch);
        }
      })
      .catch((err) => {
        console.error("===> Error response : ", err);
        setSaveLoading(false);
      });
  };

  const handleDeleteSignature = () => {
    setDeleteLoading(true);
    ApiServices.deleteSignature({
      token: auth.token,
      id: mySignature.id,
    })
      .then((res) => {
        setDeleteLoading(false);
        if (res.success === true) {
          dispatch({ type: AUTH_STATE_UPDATE });
          getData();
          sigCan?.current?.clear();
          setMySignature("");
        } else {
          handleError(res.code, dispatch);
        }
      })
      .catch((err) => {
        console.error("===> Error response : ", err);
        setDeleteLoading(false);
      });
  };
  const handleDeleteImage = (_id) => {
    setDeleteLoading(true);
    ApiServices.deleteSignature({
      token: auth.token,
      id: _id,
    })
      .then((res) => {
        setDeleteLoading(false);
        if (res.success === true) {
          dispatch({ type: AUTH_STATE_UPDATE });
          getData();
        } else {
          handleError(res.code, dispatch);
        }
      })
      .catch((err) => {
        console.error("===> Error response : ", err);
        setDeleteLoading(false);
      });
  };  
  const getData = () => {
    ApiServices.getProfileData({ token: auth.token })
      .then((res) => {
        if (res.success === true) {
          dispatch({
            type: SET_PROFILE,
            payload: {
              name: res.payload.name,
              email: res.payload.email,
              signatures: res.payload.signatures,
              options: res.payload.options,
            },
          });
        } else {
          handleError(res.code, dispatch);
        }
      })
      .catch((err) => {
        console.error("===> Error response : ", err);
      });
  };
  return fakeLoader ? (
    <></>
  ) : (
    <div className="signature-settings">
      <div className="ijtmaati-signature-modal">
        <div className="ijtmaati-signature-modal-content">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={5} lg={4}>
              <div className="input-group">
                <label htmlFor="title">Signature name</label>
                <input
                  name="title"
                  type="text"
                  placeholder="Signature name"
                  value={mySignature?.title || ""}
                  onChange={(e) =>
                    setMySignature({
                      ...mySignature,
                      ...{ title: e.target.value },
                    })
                  }
                />
              </div>
            </Grid>
          </Grid>
          <div className="draw-container">
            <div className="draw-area" style={{maxWidth: '100%'}}>
              <SignaturePad
                ref={sigCan}
                //   penColor={`${activePen.color}`}
                canvasProps={{
                  className: "sigCanvas",
                }}
                clearOnResize={true}
                onEnd={() => {
                  console.log("==> Signature Data: ", sigCan.current.toData());
                  console.log(
                    "DATA: ",
                    sigCan.current
                      .toData()
                      .map((path) =>
                        path.map((point) => ({ x: point.x, y: point.y }))
                      )
                  );
                  setMySignature({
                    ...mySignature,
                    payload: sigCan.current
                      .toData()
                      .map((path) =>
                        path.map((point) => ({ x: point.x, y: point.y }))
                      ),
                    payloadImg: sigCan.current.getTrimmedCanvas().toDataURL("image/png"),
                  });
                }}
              />
            </div>
          </div>
          <div className="actions">
            <Button
              color="inherit"
              variant="outlined"
              disableElevation
              disabled={
                mySignature && (!!mySignature?.payload || !!mySignature?.title)
                  ? false
                  : true
              }
              className="cancel"
              onClick={() => {
                sigCan?.current?.clear();
                setMySignature("");
              }}
            >
              <Close />
              <Trans>cancle</Trans>
            </Button>
            <Button
              color="inherit"
              disableElevation
              disabled={mySignature && !!mySignature?.payload ? false : true}
              variant="outlined"
              className="cancel"
              onClick={handleClear}
            >
              <AutoFixNormal />
              <Trans>signature_clear</Trans>
            </Button>
            {mySignature && !!mySignature?.id ? (
              <>
                <Button
                  color="success"
                  disableElevation
                  disabled={
                    mySignature &&
                    !!mySignature?.payload &&
                    !!mySignature?.title
                      ? false
                      : true
                  }
                  variant="outlined"
                  onClick={handleEditSignature}
                >
                  {saveLoading ? (
                    <CircularProgress
                      color="inherit"
                      style={{
                        width: "18px",
                        height: "18px",
                        marginInlineEnd: "6px",
                      }}
                    />
                  ) : (
                    <Edit />
                  )}
                  <Trans>edit_signature</Trans>
                </Button>
                <Button
                  color="error"
                  disableElevation
                  disabled={
                    mySignature &&
                    !!mySignature?.payload &&
                    !!mySignature?.title
                      ? false
                      : true
                  }
                  variant="outlined"
                  onClick={handleDeleteSignature}
                >
                  {deleteLoading ? (
                    <CircularProgress
                      color="inherit"
                      style={{
                        width: "18px",
                        height: "18px",
                        marginInlineEnd: "6px",
                      }}
                    />
                  ) : (
                    <Delete />
                  )}
                  <Trans>general.delete</Trans>
                </Button>              
              </>
            ) : (
              <Button
                color="primary"
                disableElevation
                disabled={
                  mySignature && !!mySignature?.payload && !!mySignature?.title
                    ? false
                    : true
                }
                variant="contained"
                className="cancel"
                onClick={handleAddSignature}
              >
                {saveLoading ? (
                  <CircularProgress
                    color="inherit"
                    style={{
                      width: "18px",
                      height: "18px",
                      marginInlineEnd: "6px",
                    }}
                  />
                ) : (
                  <Add />
                )}
                <Trans>add_signature</Trans>
              </Button>
            )}
          </div>
          <div className="signatures_preview_list" style={{backgroundColor: 'transparent', padding: 0}}>
            {auth.state === STATE_DONE ? (
              <Grid container spacing={2}>
                {auth?.userData?.signatures?.filter((f) => f.type === "signature")
                  .map((item) => {
                    console.log('Item: ', item);
                    return(
                    <Grid item xs={6} sm={4} md={4} lg={4} key={item.id}>
                      {false && item.payload_image &&
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                          <img style={{
                            width: '100%',
                            height: '114px',
                            objectFit: 'contain',
                            border: '1px solid var(--border-color)',
                            borderRadius: '4px',
                            padding: '8px 12px',
                            backgroundColor: 'var(--light-color)',                        
                          }} 
                          // src={ _URL_ASSETS + item.payload_image}
                          src={ Utils.parseImage(item)}
                          />
                          <Typography component="h4" className="title">
                            {item.title}
                          </Typography>

                          <Button
                            color="error"
                            disableElevation
                            variant="outlined"
                            onClick={() => handleDeleteImage(item.id)}
                            sx={{ mt: 1 }}
                          >
                            <Delete />
                            {/* <Trans>general.delete</Trans> */}
                          </Button>
                        </div>
                      }
                      {
                        item.payload && 
                        <SignaturePreview
                          title={item.title}
                          payload={JSON.parse(item.payload)}
                          handleSigClick={() => {
                            setMySignature({
                              ...item,
                              payload: JSON.parse(item.payload),
                            });
                          }}
                        />
                      }
                    </Grid>
                  )})
                }
              </Grid>
            ) : (
              <div style={{ padding: 32 }}>
                <CircularProgress
                  color="inherit"
                  style={{
                    width: "18px",
                    height: "18px",
                    marginInlineEnd: "6px",
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={deleteLoading}
          onClick={()=>{}}
        >
          <CircularProgress color="inherit" />
      </Backdrop>      
    </div>
  );
};

export default SignatureSettings;
